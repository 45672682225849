import "../assets/css/WhyChooseUs.css";
import Navbar from "./Navbar";
import r79 from "../assets/images/Rectangle 79.png";
import p1 from "../assets/images/planeterra 1.png";
import r190 from "../assets/images/Rectangle 190.png";
import r191 from "../assets/images/Rectangle 191.png";
import r192 from "../assets/images/Rectangle 192.png";
import r194 from "../assets/images/Rectangle 194.png";
import r195 from "../assets/images/Frame 2147224052.png";
import r196 from "../assets/images/ripplescore.png";
import Footer from "./Footer";

const WhyChooseUs = () => {
  return (
    <>
      <div id="top-section">
        <Navbar />
        <div className="flex flex-col justify-center items-center align-middle w-[100wv] text-[#FEFEFE] mt-[100px] px-4">
          <h1 className="font-extrabold text-3xl md:text-4xl text-center">
            Why travel with Carpedm?
          </h1>
          <p className="w-full lg:w-[1078px] font-semibold lg:text-lg sm:text-sm sm:w-[600px]  text-center">
            We’ve been the leaders in small group adventure travel for 30+
            years. How’d we get here? By listening to our travellers and giving
            them what they want: flexible itineraries, freedom to explore,
            smaller groups, safety and peace of mind, and locally based guides
            who show you your destination in a way you’d miss travelling any
            other way. What does the future of travel look like? This.
          </p>
        </div>
      </div>
      <div className="flex flex-col lg:p-[90px] justify-around w-[90vw] mx-auto md:p-[60px]">
        <div className="mb-[40px] text-start">
          <h1 className="text-3xl md:text-4xl">Dream big. Travel small.</h1>
          <p className="text-sm text-[#6D6D6D]">
            The trip of your dreams doesn’t need a Hollywood-sized budget or
            years of planning. Simply join a small group of like-minded
            travellers that, like you, are eager to experience all the things
            that make our world worth exploring.
          </p>
        </div>

        <div className="flex flex-col md:flex-row justify-around items-center gap-10">
          <div className="w-full max-w-xs md:max-w-md lg:max-w-lg">
            <img src={r79} alt="" className="w-full" />
            <h1 className="text-lg md:text-xl lg:text-2xl">01</h1>
            <h2 className="text-[#019BBB] text-md md:text-lg lg:text-xl">
              Solo travellers, step right up
            </h2>
            <p className="text-[#6D6D6D] text-sm md:text-base lg:text-lg">
              All our trips are solo-traveller-friendly, so you can enjoy the
              safety, support, and camaraderie we’re known for, even if you’re
              travelling alone. To keep costs fair, we’ll pair you with a
              same-sex roommate, or you can upgrade to My Own Room. Want trips
              exclusively for solo travellers? Check out our Solo-ish
              Adventures, designed for connection and packed with extra solo
              perks.
            </p>
          </div>

          <div className="w-full max-w-xs md:max-w-md lg:max-w-lg">
            <img src={r190} alt="" className="w-full" />
            <h1 className="text-lg md:text-xl lg:text-2xl">02</h1>
            <h2 className="text-[#019BBB] text-md md:text-lg lg:text-xl">
              You’re going to need a smaller boat.
            </h2>
            <p className="text-[#6D6D6D] text-sm md:text-base lg:text-lg">
              If you’re more the sea-faring type, we’d love to welcome you
              aboard any of the intimate vessels in our fleet. Whether you’re
              venturing to the Antarctic on the G Expedition or floating down
              French canals on a river barge, our ships carry a smaller number
              of passengers so you can enjoy your journey with plenty of room
              while still making connections.
            </p>
          </div>

          <div className="w-full max-w-xs md:max-w-md lg:max-w-lg">
            <img src={r191} alt="" className="w-full" />
            <h1 className="text-lg md:text-xl lg:text-2xl">03</h1>
            <h2 className="text-[#019BBB] text-md md:text-lg lg:text-xl">
              Join 10-to-12-ish fellow adventurers.
            </h2>
            <p className="text-[#6D6D6D] text-sm md:text-base lg:text-lg">
              We keep our groups small to create a sense of camaraderie and
              allow for closer connections to the places and people you visit.
              Most of our land tours have no more than 16 travellers and usually
              average 10-12 people.
            </p>
          </div>
        </div>
      </div>

      <div className="bg-[#EFFAFF] p-6 lg:p-[120px]">
        <div>
          <h1 className="text-2xl">Our guides are all CEOs.</h1>
          <p className="text-lg text-[#6D6D6D]">
            At G Adventures, we don't have tour guides — we have Chief
            Experience Officers. Our founder, Bruce Poon Tip, gave up his title
            as CEO because he felt they needed a proper title to reflect how
            integral they are to giving you an exceptional travel experience.
            (But don't worry, they won't be using any office attire or business
            jargon.)
          </p>
        </div>
        <div className="flex flex-col md:flex-row justify-around gap-10 md:gap-20 align-middle items-center mt-10">
          <div>
            <img src={r192} alt="" className="lg:min-w-[400px] max-w-xs" />
          </div>
          <div>
            <div>
              <h1 className="text-xl text-[#019BBB]">
                Locally based = local experts.
              </h1>
              <p className="text-sm text-[#6D6D6D]">
                Our CEOs are locally based, meaning they know the area you’re
                exploring like the back of their well-travelled hand. They plan
                visits around the best times to visit (or avoid) the most
                popular spots, can help you choose local restaurants, and
                generally give you great tips on the best things to experience.
                And since they usually speak the local language, they are your
                go-to resource for anything that comes up.
              </p>
            </div>
            <div>
              <h1 className="text-xl text-[#019BBB]">
                Local support. All. The. Time.
              </h1>
              <p className="text-sm text-[#6D6D6D]">
                All CEOs are supported by our local operations teams 24/7. If
                something comes up that your CEO can’t help you with (however
                unlikely that is), the team nearby is there to support them in
                keeping you safe and happy.
              </p>
            </div>
            <div>
              <h1 className="text-xl text-[#019BBB]">
                Your experience is their focus.{" "}
              </h1>
              <p className="text-sm text-[#6D6D6D]">
                We want you to have the time of your life — that’s why we hire
                the smartest, friendliest, bestest CEOs around. It’s their
                mission to make sure you return home feeling like your life has
                truly been changed.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="p-6 lg:p-[120px]">
        <div className="text-center mb-10">
          <h1 className="text-3xl md:text-4xl">Travel like you live there.</h1>
          <p className="text-lg text-[#6D6D6D]">
            Since our very first tour, our goal has been to connect travellers
            with local communities on a deeper level. These connections go
            beyond personal ones to help give back to the wider community of
            your destination and ensure the people who live there get as much as
            they give. Changing the world is easy — all you have to do is have
            the time of your life.
          </p>
        </div>
        <div className="flex flex-col md:flex-row justify-around gap-10">
          <div className="flex flex-col w-full md:w-1/2">
            <h1 className="text-xl md:text-2xl">01</h1>
            <h2 className="text-xl text-[#019BBB]">
              You stay local. So does your money.
            </h2>
            <p className="text-sm text-[#6D6D6D]">
              Multi-floor, thousand-room hotel behemoths? Not on our tours. We
              go for the connections, charm, and authenticity that can only be
              found in local accommodations, transport, and restaurants. This
              also allows us to stay at smaller hotels and eat at restaurants
              with fewer people. Plus, using local service providers helps
              support the local economy and the people that make the places we
              travel so special.
            </p>
          </div>
          <div className="flex flex-col w-full md:w-1/2">
            <h1 className="text-xl md:text-2xl">02</h1>
            <h2 className="text-xl text-[#019BBB]">
              You stay local. So does your money.
            </h2>
            <p className="text-sm text-[#6D6D6D]">
              Multi-floor, thousand-room hotel behemoths? Not on our tours. We
              go for the connections, charm, and authenticity that can only be
              found in local accommodations, transport, and restaurants. This
              also allows us to stay at smaller hotels and eat at restaurants
              with fewer people. Plus, using local service providers helps
              support the local economy and the people that make the places we
              travel so special.
            </p>
          </div>
        </div>
      </div>

      <div className="p-6 lg:p-[120px] bg-[#EFFAFF]">
        <div className="text-center mb-10">
          <h1 className="text-3xl md:text-4xl">
            Enjoy more flexibility than a yoga class
          </h1>
          <p className="text-lg text-[#6D6D6D]">
            Adventure is all about having the freedom to follow your curiosity
            and discovering the unknown. No matter the Travel Style, our tours
            balance well-planned itineraries with the flexibility to do your own
            thing and explore a bit on your own. From optional activities to
            free time, you can choose an adventure that will feel truly like
            your own.
          </p>
        </div>
        <div className="flex flex-col lg:flex-row justify-around gap-10">
          <div className="flex flex-col gap-10 w-full lg:w-1/2">
            <div>
              <h1 className="text-lg text-[#019BBB]">
                Whoa, that’s a lot of tours.
              </h1>
              <p className="text-sm text-[#6D6D6D]">
                We have tons of tours. No, really. Tours for you, your family,
                your friends, your buddy from work who really likes nature
                documentaries. First trip ever and want to see all the
                highlights? Got that. Heading to your seventh continent and want
                to be treated like the experienced wanderer you are? Got that
                too. Our 10 unique Travel Styles group tours together into
                themes based on what you like or how you want to travel.
              </p>
            </div>
            <div>
              <h1 className="text-lg text-[#019BBB]">Personalize your tour.</h1>
              <p className="text-sm text-[#6D6D6D]">
                Squeeze every last drop of adventure out of your tour by
                customizing it to your liking. Add on amazing optional
                activities throughout your tour like hikes, local cooking
                classes, or helicopter rides depending on where you’re headed.
                Want extra time for exploring? Book pre- or post-trip
                accommodations before or after your tour. Or let us give you a
                one-day-or-less introduction to your destination on one of our
                separate Day Tours.
              </p>
            </div>
            <div>
              <h1 className="text-lg text-[#019BBB]">
                Get your share of free time.
              </h1>
              <p className="text-sm text-[#6D6D6D]">
                If you want to do some solo exploring or maybe just need a nap
                (it happens to the best of us), our itineraries feature built-in
                free time to let you do your thing.
              </p>
            </div>
          </div>
          <div className="flex justify-center lg:w-1/2">
            <img
              src={r194}
              alt=""
              className="w-full max-w-xs lg:min-w-[400px]"
            />
          </div>
        </div>
      </div>

      <div className="p-6 lg:p-[120px]">
        <div className="mb-10">
          <h1 className="text-3xl md:text-4xl">G for Good</h1>
          <p className="text-[#6D6D6D]">
            When you travel with us, you see our commitment to making travel a
            force for good is more than just words on our website — it’s in
            everything we do. G for Good stands for all of the social,
            environmental and ethical good we create as a business which helps
            to fuel Community Tourism. Our efforts to preserve and conserve our
            world fall under two approaches: Acting Responsibly, which is our
            commitment to improve the sustainability of our own operations, and
            Creating Positive Impact, which focuses on leaving the right impact
            in the places we visit.
          </p>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10">
          <div className="flex flex-col rounded-[24px] bg-[#F5F5F5] p-[35px]">
            <h1 className="text-lg">Animal Welfare</h1>
            <p className="text-sm text-[#6D6D6D]">
              We recognize the importance of animal welfare. That’s why we have
              worked with experts from World Animal Protection, The Jane Goodall
              Institute and The World Cetacean Alliance to draw up a robust
              policy, which builds on the ABTA Global Welfare Guidelines for
              Animals in Tourism and puts the needs of the animals first. We
              want to ensure that all animals featured as part of our tours are
              treated humanely.
            </p>
            <a href="" className="text-[#019BBB]">
              learn more
            </a>
          </div>
          <div className="flex flex-col rounded-[24px] bg-[#F5F5F5] p-[35px]">
            <h1 className="text-lg">Child Welfare</h1>
            <p className="text-sm text-[#6D6D6D]">
              It is critical that no child is ever harmed as a result of
              tourism. Travellers have the power to make a true impact in the
              communities they visit, and that’s why we actively work to ensure
              child welfare is protected in the places we operate.
            </p>
            <a href="" className="text-[#019BBB]">
              learn more
            </a>
          </div>
          <div className="flex flex-col rounded-[24px] bg-[#F5F5F5] p-[35px]">
            <h1 className="text-lg">Respecting local cultures</h1>
            <p className="text-sm text-[#6D6D6D]">
              Connecting curious travellers with Indigenous communities is an
              essential part of our identity at G Adventures. We are committed
              to respecting the rights, history, and culture of Indigenous
              people while ensuring that tourism supports their well-being.
            </p>
            <a href="" className="text-[#019BBB]">
              learn more
            </a>
          </div>
          <div className="flex flex-col rounded-[24px] bg-[#F5F5F5] p-[35px]">
            <h1 className="text-lg">Plastics Partnership Project</h1>
            <p className="text-sm text-[#6D6D6D]">
              Plastic pollution is a huge global problem. G Adventures is
              working with our partners to eliminate as much single-use plastic
              on our tours as possible.
            </p>
            <a href="" className="text-[#019BBB]">
              learn more
            </a>
          </div>
          <div className="flex flex-col rounded-[24px] bg-[#F5F5F5] p-[35px]">
            <h1 className="text-lg">Traveller Conduct Policy</h1>
            <p className="text-sm text-[#6D6D6D]">
              We recognize the importance of animal welfare. That’s why we have
              worked with experts from World Animal Protection, The Jane Goodall
              Institute and The World Cetacean Alliance to draw up a robust
              policy, which builds on the ABTA Global Welfare Guidelines for
              Animals in Tourism and puts the needs of the animals first. We
              want to ensure that all animals featured as part of our tours are
              treated humanely.
            </p>
            <a href="" className="text-[#019BBB]">
              learn more
            </a>
          </div>
          <div className="flex flex-col rounded-[24px] bg-[#F5F5F5] p-[35px]">
            <h1 className="text-lg">LGBTQ+ inclusivity at Carpedm</h1>
            <p className="text-sm text-[#6D6D6D]">
              Our team around the world deeply values diversity, inclusivity,
              and the power of community. Every traveller deserves to feel
              valued and to be their authentic selves on their journey with us.
              See all of the ways we wholeheartedly embrace and support the
              LGBTQ+ community on tour, in our culture, and around the world.
            </p>
            <a href="" className="text-[#019BBB]">
              learn more
            </a>
          </div>
        </div>
      </div>

      <div className="p-6 lg:p-[120px]">
        <div className="mb-10">
          <h1 className="font-extrabold text-3xl md:text-4xl">
            Creating Positive Impact
          </h1>
        </div>
        <div className="flex flex-col md:flex-row justify-around gap-10">
          <div className="flex flex-col w-full max-w-[400px]">
            <img src={p1} alt="" className="w-[200px] self-center" />
            <h1 className="text-lg text-center">Planeterra</h1>
            <p className="text-sm text-[#6D6D6D] text-center">
              Established in 2003, Planeterra is our non-profit partner
              dedicated to ensuring communities touched by tourism benefit from
              the opportunities it provides. They help empower local people to
              develop their communities, conserve their cultures, and create
              humane and supportive systems for their endeavours.
            </p>
            <a href="" className="text-[#019BBB] text-center">
              learn more
            </a>
          </div>
          <div className="flex flex-col w-full max-w-[400px]">
            <img src={r195} alt="" className="w-[150px] self-center" />
            <h1 className="text-lg text-center">Ripple Score</h1>
            <p className="text-sm text-[#6D6D6D] text-center">
              For years we’ve talked about how we do travel differently,
              especially when it comes to working with local businesses and
              services to build our tours. You deserve to see exactly what we
              mean when we say we’re changing lives through travel.
            </p>
            <a href="" className="text-[#019BBB] text-center">
              learn more
            </a>
          </div>
          <div className="flex flex-col w-full max-w-[400px]">
            <img src={r196} alt="" className="w-[150px] self-center" />
            <h1 className="text-lg text-center">G Values Fund</h1>
            <p className="text-sm text-[#6D6D6D] text-center">
              Our CEOs are talented guides dedicated to making our tours as
              amazing as possible. But leading tours is hard work, and not
              everyone wants to do it forever. The G Values Fund is a microloan
              program instituted by G Adventures that helps former CEOs
              transition to life once their days on the road are through by
              helping them establish self-sustaining businesses that can be
              linked to G Adventures tour itineraries. That way, they continue
              to share all of their amazing knowledge, experiences, and
              personality with our world — just in a different way.
            </p>
            <a href="" className="text-[#019BBB] text-center">
              learn more
            </a>
          </div>
        </div>
      </div>

      <Footer></Footer>
    </>
  );
};

export default WhyChooseUs;
