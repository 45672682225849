import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import "../assets/css/Homepage.css";
import img from "../assets/images/harshil-gudka-pU-9BYqjhyo-unsplash.jpg";
import Footer from "./Footer";
import Navbar from "./Navbar";
import TourCard from "./TourCard";
import { cityRecommendations } from "./RecommendationCities";
import {
  clearSearchResults,
  fetchAllTours,
  fetchConversionRates,
  fetchTourDetails,
  searchToursCityBased,
} from "../redux/home";

const Homepage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { searchResults, tourDetails } = useSelector((state) => state.Tours);
  const [searchInput, setSearchInput] = useState("");
  const [recommendations, setRecommendations] = useState([]);
  // const apiPage = 1;

  const ratesAPIKey = process.env.REACT_APP_RATES_API_KEY;
  const apikey = process.env.REACT_APP_API_KEY;

  useEffect(() => {
    dispatch(fetchAllTours({ apikey }));
    dispatch(fetchConversionRates({ ratesAPIKey }));
  }, [dispatch, ratesAPIKey, apikey]);

  const handleSearch = (string) => {
    navigate(`/tours?name=${string}`); // Ensure correct variable is used for URL

    if (string?.trim() === "") {
      dispatch(clearSearchResults()); // Reset search results to all tours
    } else {
      // Make sure the correct values are passed
      dispatch(searchToursCityBased({ inputValue: string, apikey }))
        .unwrap()
        .then((result) => {
          if (result.length > 0) {
            // Navigate if there are results
            // navigate(`/tours?name=${string}`); // Ensure correct variable is used for URL
          } else {
            console.log("No results found");
          }
        })
        .catch((error) => {
          console.error("Error during search:", error);
        });
    }
  };

  const handleViewAll = () => {
    navigate(`/tours?name=${searchInput}`);
  };

  const handleEcuadorButton = () => {
    navigate(`/tours?name=Ecuador`);
  };

  const fetchRecommendations = (query) => {
    // Filter the cityRecommendations based on the user input
    return cityRecommendations.filter((city) =>
      city.toLowerCase().includes(query.toLowerCase())
    );
  };

  const handleInputChange = (e) => {
    console.log(e.target.value);
    const query = e.target.value;
    console.log(query);
    setSearchInput(query);

    if (query.length > 0) {
      const fetchedRecommendations = fetchRecommendations(query);
      setRecommendations(fetchedRecommendations); // Set recommendations if input is not empty
    } else {
      setRecommendations([]); // Clear recommendations if input is empty
    }
  };

  // Handle selecting a recommendation
  const handleRecommendationClick = (recommendation) => {
    setSearchInput(recommendation); // Set the selected recommendation as input
    setRecommendations([]); // Clear recommendations after selection
    //handleSearch(recommendation); // Optionally trigger the search
  };

  // const handleFocus = () => {
  //   const sectionsToBlur = [
  //     ".top-section",
  //     ".hero-section",
  //     ".middle-section",
  //     ".explore-section",
  //     ".bottom-section",
  //   ];

  //   sectionsToBlur.forEach((section) => {
  //     const element = document.querySelector(section);
  //     if (element) element.classList.add("blur-background");
  //   });
  // };

  // const handleBlur = () => {
  //   const sectionsToBlur = [
  //     ".top-section",
  //     ".hero-section",
  //     ".middle-section",
  //     ".explore-section",
  //     ".bottom-section",
  //   ];

  //   sectionsToBlur.forEach((section) => {
  //     const element = document.querySelector(section);
  //     if (element) element.classList.remove("blur-background");
  //   });
  // };

  useEffect(() => {
    if (searchResults.length > 0) {
      dispatch(fetchTourDetails({ searchResults, apikey }));
    }
  }, [dispatch, searchResults, apikey]);
  return (
    <main>
      <div className="top-section">
        <Navbar />
        <div className="hero">
          <div className="hero-text">
            <div>
              <h1>Adventure Awaits!</h1>{" "}
              <strong>
                Explore the World <br />
                in{" "}
              </strong>{" "}
              <strong style={{ color: "#019BBB" }}> Small Groups!</strong>
            </div>
            <p>
              Join like-minded travelers on unforgettable experiences across the
              globe!
            </p>
          </div>
          <div className="search-container">
            <div className="search-bar">
              <input
                type="search"
                placeholder="Pick a Place We'll Take You There!"
                value={searchInput}
                onChange={(e) => {
                  handleInputChange(e);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSearch(searchInput);
                  }
                }}
              />

              <div className="search-text-container">
                <label
                  htmlFor="search"
                  className="search-text"
                  style={{ cursor: "pointer" }}
                  onClick={() => handleSearch(searchInput)}
                >
                  Search
                </label>
                <span
                  id="search"
                  className="search-icon"
                  onClick={() => {
                    handleSearch(searchInput);
                  }}
                >
                  <i className="fas fa-search"></i>
                </span>
              </div>
              {recommendations.length > 0 && (
                <ul className="recommendations-dropdown">
                  {recommendations.map((recommendation, index) => (
                    <li
                      key={index}
                      onClick={() => handleRecommendationClick(recommendation)}
                      className="recommendation-item"
                    >
                      {recommendation}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="middle-section">
        <div className="middle-text-section">
          <div className="middle-section-headings">
            <h1>The Ecuadors Tours</h1>
            <h3>Adventure at its best</h3>
          </div>
          <div className="middle-section-paragraph">
            <p>
              The ultimate way to explore the world! With many trips across
              multiple destinations, enjoy perfectly paced itineraries, unique
              stays, and elevated dining experiences. Get ready to dive deeper
              into your destination with the perfect blend of adventure and
              comfort. The grandest adventures on Earth are waiting for you!
            </p>
          </div>
          <div className="middle-section-btn">
            <button onClick={handleEcuadorButton}>
              View All the Ecuadors tours
            </button>
          </div>
        </div>
        <div className="img-section">
          <img src={img} alt="img" />
        </div>
      </div>
      <div className="explore-section">
        <div className="explore-text-section">
          <h1>Explore Carpedm Tours: Save Up to 40% on Expedition Cruises!</h1>
          <Link
            to={`/tours?name=${searchInput}`}
            onClick={handleViewAll}
            style={{ fontSize: "20px" }}
          >
            View all
          </Link>
        </div>
        <div className="explore-section-cards">
          {searchResults.length > 0 ? (
            searchResults
              .slice(0, 3)
              .map((tour) => (
                <TourCard key={tour.id} tour={tour} tourDetails={tourDetails} />
              ))
          ) : (
            <div>No tours found for your search.</div>
          )}
        </div>
      </div>
      <div className="bottom-section">
        <div className="bottom-section-titles">
          <h1>Why travel with Carpedm?</h1>
          <h3>Adventure Awaits, With a Twist of Fun!</h3>
        </div>
        <div className="bottom-section-description">
          <p>
            At Carpedm, we’re redefining what it means to travel. With over 17
            years of experience, we’ve mastered the art of adventure, combining
            unforgettable experiences with a touch of freedom, flexibility, and
            the chance to give back. Discover how we’re shaking up the world of
            travel and creating journeys you’ll remember forever!
          </p>
        </div>
        <div className="bottom-section-cards-wrapper">
          <div className="bottom-section-card card-1">
            <div className="bottom-card-text">
              <h3>Small Group</h3>
              <p>
                Travel’s more fun with friends! Join a tight-knit group of
                adventure-seekers like you, ready to explore the world in ways
                that’ll leave you with stories worth sharing!
              </p>
            </div>
          </div>
          <div className="bottom-section-card card-2">
            <div className="bottom-card-text">
              <h3>100% guaranteed departures</h3>
              <p>
                No cancellations here! Pack your bags and get ready to go—if
                it’s on the schedule, you’re in for a sure-fire adventure!
                Ready, set, explore!
              </p>
            </div>
          </div>
          <div className="bottom-section-card card-3">
            <div className="bottom-card-text">
              <h3>Locally based guides</h3>
              <p>
                Our (CEOs) are your ultimate travel gurus, with insider
                knowledge of every destination are ready to guide you to hidden
                gems and unforgettable experiences!
              </p>
            </div>
          </div>
          <div className="bottom-section-card card-4">
            <div className="bottom-card-text">
              <h3>Built to support local communities</h3>
              <p>
                Adventure with heart! Every tour is designed to give back, so
                you’ll be helping local communities thrive while having the time
                of your life.
              </p>
            </div>
          </div>
          <div className="bottom-section-card card-5">
            <div className="bottom-card-text">
              <h3>Flexibility, freedom & fun</h3>
              <p>
                Your adventure, your rules! Choose your pace, create your
                perfect day, and leave room for spontaneous fun in between.
              </p>
            </div>
          </div>
          <div className="bottom-section-card card-6">
            <div className="bottom-card-text">
              <h3>G for Good</h3>
              <p>
                Embark on unforgettable adventures while making a positive
                impact on the places you explore. Travel that feels as good as
                it looks!
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </main>
  );
};

export default Homepage;
