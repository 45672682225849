import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import "../../assets/css/blogs/blogs.css";
import {
  fetchUserDetails,
  updateUserDetails,
  updatePassword,
} from "../../redux/account";

const AccountPage = () => {
  const dispatch = useDispatch();
  const { userDetails, error } = useSelector((store) => store.Account);

  const { register, handleSubmit, setValue, watch } = useForm();

  const country = watch("country");
  const region = watch("province");

  const handleCountryChange = (val) => {
    setValue("country", val);
  };

  const handleRegionChange = (val) => {
    setValue("province", val);
  };

  const {
    register: registerPassword,
    handleSubmit: handlePasswordSubmit,
    formState: { errors: passwordErrors },
  } = useForm();

  // State to manage visibility of different sections
  const [activeSection, setActiveSection] = useState("personalInfo");

  useEffect(() => {
    dispatch(fetchUserDetails());
  }, [dispatch]);

  useEffect(() => {
    if (userDetails) {
      Object.keys(userDetails).forEach((key) => {
        setValue(key, userDetails[key]);
      });
    }
  }, [userDetails, setValue]);

  useEffect(() => {
    if (error) {
      toast.error(error);
    }
  }, [error]);

  const handleSectionChange = (section) => {
    setActiveSection(section);
  };

  const onSubmit = (data) => {
    dispatch(updateUserDetails(data))
      .unwrap()
      .then(() => {
        toast.success("Profile updated successfully!");
      })
      .catch((error) => {
        toast.error(error || "Profile update failed!");
      });
  };

  const onPasswordSubmit = (data) => {
    console.log("data", data);
    if (data.newPassword !== data.confirmNewPassword) {
      toast.error("New passwords do not match!");
      return;
    }
    dispatch(updatePassword(data))
      .unwrap()
      .then(() => {
        toast.success("Password updated successfully!");
      })
      .catch((error) => {
        toast.error(error || "Password update failed!");
      });
  };

  return (
    <main className="w-full h-auto overflow-hidden mb-20">
      <div className="w-full h-auto flex flex-col justify-center items-start ml-4 md:ml-[150px]">
        <h1 className="font-extrabold text-2xl md:text-3xl">
          Profile Settings
        </h1>
      </div>
      <div className="flex flex-col justify-center items-center w-full h-auto">
        <div className="bg-[#019BBB] flex flex-col md:flex-row justify-around items-center w-[90%] md:w-[70%] h-auto min-h-[80px] sm:min-h-[100px] rounded-[24px]">
          <p
            className="text-white no-underline cursor-pointer"
            onClick={() => handleSectionChange("personalInfo")}
          >
            Personal info
          </p>
          <p
            className="text-white no-underline cursor-pointer"
            onClick={() => handleSectionChange("paymentInfo")}
          >
            Payment info
          </p>
          <p
            className="text-white no-underline cursor-pointer"
            onClick={() => handleSectionChange("privacySecurity")}
          >
            Privacy Security
          </p>
          <p
            className="text-white no-underline cursor-pointer"
            onClick={() => handleSectionChange("preferences")}
          >
            Preferences
          </p>
        </div>
      </div>
      <form
        className="flex flex-col ml-4 md:ml-[150px] w-[calc(90%-16px)] md:w-[calc(100%-150px)] h-auto"
        onSubmit={handleSubmit(onSubmit)}
      >
        {activeSection === "personalInfo" && (
          <div className="flex flex-col">
            <p>View your personal information</p>
            <div>
              <select
                name="title"
                className="w-[200px] mb-10 border-cyan-600"
                {...register("title")}
              >
                <option value="">Title</option>
                <option value="Mr">Mr</option>
                <option value="Ms">Ms</option>
                <option value="Mrs">Mrs</option>
              </select>
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-2 md:grid-cols-1 gap-4 max-w-[90%] md:max-w-[70%]">
              <input
                type="text"
                name="firstName"
                placeholder="First Name"
                className="border rounded-[10px] h-[40px] p-2"
                {...register("firstName")}
              />
              <input
                type="text"
                name="lastName"
                placeholder="Last Name"
                className="border rounded-[10px] h-[40px] p-2"
                {...register("lastName")}
              />
              <input
                type="text"
                name="middleName"
                placeholder="Middle Name"
                className="border rounded-[10px] h-[40px] p-2"
                {...register("middleName")}
              />
              <input
                type="date"
                name="dateOfBirth"
                className="border rounded-[10px] h-[40px] p-2"
                {...register("dateOfBirth")}
              />
            </div>
            <div className="mt-4">
              <input type="checkbox" id="checkbox" className="w-4 h-4" />
              <label htmlFor="checkbox" className="text-gray-400 text-sm">
                Traveler doesn’t have a middle name
              </label>
            </div>

            <div className="mt-4">
              <p>Passport Information</p>
              <div className="grid grid-cols-1 lg:grid-cols-2 md:grid-cols-1  gap-4 max-w-full md:max-w-[70%]">
                <input
                  type="text"
                  name="placeOfBirth"
                  placeholder="Place of birth"
                  className="border rounded-[10px] h-[40px] p-2"
                  {...register("placeOfBirth")}
                />
                <input
                  type="text"
                  name="nationality"
                  placeholder="Nationality"
                  className="border rounded-[10px] h-[40px] p-2"
                  {...register("nationality")}
                />
                <input
                  type="text"
                  name="passportNumber"
                  placeholder="Passport Number"
                  className="border rounded-[10px] h-[40px] p-2"
                  {...register("passportNumber")}
                />
                <input
                  type="date"
                  name="passportExpiry"
                  className="border rounded-[10px] h-[40px] p-2"
                  {...register("passportExpiry")}
                />
              </div>
            </div>

            <div className="mt-4">
              <p>Contact Info</p>
              <div className="grid grid-cols-1 lg:grid-cols-2 md:grid-cols-1 gap-4 max-w-full md:max-w-[70%]">
                <input
                  type="text"
                  name="mailingAddress"
                  placeholder="Mailing Address"
                  className="border rounded-[10px] h-[40px] p-2"
                  {...register("mailingAddress")}
                />
                <input
                  type="text"
                  name="city"
                  placeholder="City"
                  className="border rounded-[10px] h-[40px] p-2"
                  {...register("city")}
                />
                <div>
                  <input
                    type="text"
                    name="postalCode"
                    className="w-[100px] mr-6"
                    placeholder="Postal Code"
                    {...register("postalCode")}
                  />
                  <RegionDropdown
                    name="province"
                    country={country}
                    value={region}
                    onChange={handleRegionChange}
                    classes="border-cyan-600 rounded-[10px] h-[55px] p-2 w-[325px] md:max-w-[380px] md:mt-[10px] md:mt-[10px]"
                  />
                </div>

                <CountryDropdown
                  name="country"
                  value={country}
                  onChange={handleCountryChange}
                  classes="border-cyan-600 rounded-[10px] h-[55px] p-2 max-w-[470px]"
                />
                <input
                  type="text"
                  name="email"
                  placeholder="Email Address"
                  className="border rounded-[10px] h-[40px] p-2"
                  {...register("email")}
                />
                <input
                  type="text"
                  name="phone"
                  placeholder="Phone"
                  className="border rounded-[10px] h-[40px] p-2"
                  {...register("phone")}
                />
              </div>
            </div>
            <div className="mt-8">
              <button
                type="submit"
                className="border-none bg-[#019BBB] w-full md:w-[400px] h-[50px] rounded-[14px] text-white"
              >
                Save Information
              </button>
            </div>
          </div>
        )}

        {activeSection === "paymentInfo" && (
          <div className="flex flex-col mt-8">
            <h2 className="font-bold text-lg">Payment Information</h2>
            <p>Your payment history and refunds will be displayed here</p>
          </div>
        )}

        {activeSection === "preferences" && (
          <div className="flex flex-col mt-8 ml-4 md:ml-[100px]">
            <h2 className="font-bold text-lg text-[#019BBB]">
              Notifications Preferences
            </h2>
            <div>
              <p>Email me</p>
              <div>
                <input type="checkbox" id="emailUpdates1" className="w-4 h-4" />
                <label
                  htmlFor="emailUpdates1"
                  className="text-gray-400 text-lg"
                >
                  Daily CarpeDM Blog posts, Promotions & Deals
                </label>
              </div>
              <div>
                <input type="checkbox" id="emailUpdates2" className="w-4 h-4" />
                <label
                  htmlFor="emailUpdates2"
                  className="text-gray-400 text-lg"
                >
                  Poetry CarpeDM
                </label>
              </div>
              <div className="mt-8 flex flex-col md:flex-row gap-4">
                <button className="border-none w-full md:w-[150px] h-[60px] bg-[#019BBB] text-white rounded-[10px]">
                  Save Updates
                </button>
                <button className="w-full md:w-[150px] h-[60px] bg-transparent text-[#019BBB] rounded-[10px] border-cyan-500">
                  Discard Changes
                </button>
              </div>
            </div>
          </div>
        )}
      </form>
      {activeSection === "privacySecurity" && (
        <div className="flex flex-col mt-8 ml-4 md:ml-[110px]">
          <h2 className="font-bold text-xl text-[#019BBB]">
            Login Information
          </h2>
          <div className="mt-8">
            <p>Your current login email is: {userDetails?.email}</p>
            <p>Need to change email? Contact support</p>
          </div>
          <div className="mt-20">
            <p className="text-xl">Update Password</p>
            <form
              onSubmit={handlePasswordSubmit(onPasswordSubmit)}
              className="flex flex-col gap-6 w-[calc(90%-16px)] md:w-[calc(100%-16px)]"
            >
              <input
                type="password"
                placeholder="Current Password"
                className="border rounded-[10px] h-[40px] p-2"
                {...registerPassword("currentPassword", { required: true })}
              />
              {passwordErrors.currentPassword && (
                <span>This field is required</span>
              )}
              <input
                type="password"
                placeholder="New Password"
                className="border rounded-[10px] h-[40px] p-2"
                {...registerPassword("newPassword", { required: true })}
              />
              {passwordErrors.newPassword && (
                <span>This field is required</span>
              )}
              <input
                type="password"
                placeholder="Confirm New Password"
                className="border rounded-[10px] h-[40px] p-2"
                {...registerPassword("confirmNewPassword", {
                  required: true,
                })}
              />
              {passwordErrors.confirmNewPassword && (
                <span>This field is required</span>
              )}
              <div className="mt-8">
                <button
                  type="submit"
                  className="border-none rounded-[10px] w-full md:w-[300px] h-[60px] bg-[#019BBB] text-white"
                >
                  Update Password
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </main>
  );
};

export default AccountPage;
