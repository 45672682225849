import React, { useEffect, useState } from "react";
import "../../assets/css/bookingTour/booking.css";
import "react-multi-date-picker/styles/layouts/mobile.css";
import { Calendar } from "react-multi-date-picker";
const TourDates = () => {
  const [selectedDates, setSelectedDates] = useState(new Date());
  const [numberOfMonths, setNumberOfMonths] = useState(2);
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setNumberOfMonths(1); // Set to 1 month on small screens
      } else {
        setNumberOfMonths(2); // Set to 2 months on larger screens
      }
    };

    // Check the screen size on initial load
    handleResize();

    // Add event listener to detect window resize
    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div className="booking-calender">
      <Calendar
        multiple={true}
        numberOfMonths={numberOfMonths}
        shadow={true}
        highlightToday={true}
        value={selectedDates}
        onChange={setSelectedDates}
        // className="tour-date-picker"
        className="rmdp-mobile"
        mobileLabels={{
          OK: "Accept",
          CANCEL: "Close",
        }}
        format="DD MMMM YYYY"
        mapDays={({ date }) => {
          let isWeekend = [0, 6].includes(date.weekDay.index);

          if (isWeekend)
            return {
              disabled: true,
              style: { color: "#ccc" },
              onClick: () => alert("weekends are disabled"),
            };
        }}
      />
    </div>
  );
};

export default TourDates;
