import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";

const TourCard = ({ tour, tourDetails, imageBannerView }) => {
  const location = useLocation();
  const { conversionRates } = useSelector((state) => state.Tours);

  // const [conversionRates, setConversionRates] = useState({});
  // console.log("conversionRates:::::::::::", conversionRates)
  // const [loading, setLoading] = useState(true);
  // const ratesAPIKey = 'fbd805cee8d210e5dfbe212d';

  // Improved price logic to correctly handle currency filtering
  const priceInfo =
    tour.advertised_departures?.find((dep) =>
      ["USD", "AUD", "CHF", "KRW", "EUR"].includes(dep.currency)
    ) || tour.advertised_departures?.[0];

  const price = priceInfo ? priceInfo.amount : null;

  // Return null if the price is invalid
  if (price === null || isNaN(price)) {
    return null; // Don't render the card if there's no valid price
  }

  // Convert price to USD using fetched rates
  const currency = priceInfo?.currency || "USD";
  const conversionRate = conversionRates[currency] || 1; // Default to 1 if USD
  const convertedPrice = price / conversionRate;

  // Format the converted price as USD
  const formattedPrice = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(convertedPrice);

  const bannerImage =
    imageBannerView === "image"
      ? tourDetails?.[tour.id]?.images?.find((img) => img.type === "BANNER")
          ?.image_href || "fallback-image-url.jpg"
      : imageBannerView === "map"
      ? tourDetails?.[tour.id]?.images?.find((img) => img.type === "MAP")
          ?.image_href || "fallback-image-url.jpg"
      : tourDetails?.[tour.id]?.images?.find((img) => img.type === "BANNER")
          ?.image_href || "fallback-image-url.jpg";

  const itinerary =
    tour.structured_itineraries?.length > 0
      ? tour.structured_itineraries[0].valid_during_ranges[0]
      : null;

  // console.log('start valid::::::', tour.departures_start_date);
  // console.log('end valid::::::', tour.departures_end_date);
  // // Improved price logic to correctly handle currency filtering
  // const priceInfo =
  //   tour.advertised_departures?.find((dep) =>
  //     ['USD', 'AUD', 'CHF', 'KRW', 'EUR'].includes(dep.currency)
  //   ) || tour.advertised_departures?.[0];

  // const price = priceInfo ? priceInfo.amount : null;

  // // Return null if the price is invalid
  // if (price === null || isNaN(price)) {
  //   return null; // Don't render the card if there's no valid price
  // }

  const fullDescription =
    tourDetails?.[tour.id]?.description || "Description not available";

  // Truncate description to approx 100 characters
  const truncatedDescription =
    fullDescription.length > 100
      ? `${fullDescription.substring(0, 100)}...`
      : fullDescription;

  // Conditional class assignment based on route
  const className =
    location.pathname === "/"
      ? "explore-card"
      : location.pathname === "/tours"
      ? "explore-card-tours"
      : "";

  return (
    <div className={className} key={tour.id}>
      {/* Banner Image */}
      <img src={bannerImage} alt={tour.name} />

      <div className="explore-card-title">
        <h1>{tour.name}</h1>
        {itinerary ? (
          <>
            <p>
              Valid from {itinerary.start_date}
              {itinerary.end_date && ` to ${itinerary.end_date}`}
            </p>
            {/* <p>
              Valid to {itinerary.start_date}
              {itinerary.end_date && ` to ${itinerary.end_date}`}
            </p> */}
          </>
        ) : (
          <p>No valid dates available</p>
        )}
      </div>

      {/* Description */}
      <div className="explore-card-description">
        <p>{truncatedDescription}</p>
      </div>

      <hr />

      {/* Footer with Price and Button */}
      <div className="explore-card-footer">
        <div className="explore-card-footer-text">
          {/* <h1>${price}</h1> */}
          <h1>{formattedPrice}</h1>
          <p>/</p>
          <p>Per Person</p>
        </div>
        {/* <Link href={`/tours/${tour.id}`} target='_blank' rel='noopener noreferrer'>
          <button>View Itinerary</button>
        </Link> */}
        <Link to={`/tours/${tour.id}`}>
          <button>View Itinerary</button>
        </Link>
      </div>
    </div>
  );
};

export default TourCard;
