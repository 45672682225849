import React, { useState, useEffect } from "react";
import "../assets/css/Signup.css";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { signup } from "../redux/authSlice";
import logo from "../assets/images/logo.png";

const Signup = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isAuthenticated } = useSelector((state) => state.auth); // Get authentication state

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/");
    }
  }, [isAuthenticated, navigate]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate that password and confirmPassword match
    if (formData.password !== formData.confirmPassword) {
      toast.error("Passwords do not match!");
      return; // Stop the submission process if passwords don't match
    }

    try {
      // Dispatch the signup action with the form data
      await dispatch(signup(formData)).unwrap();
      toast.success("Signup successful!");
    } catch (error) {
      toast.error(error || "Signup failed!");
    }
  };

  return (
    <>
      <div className="logo">
        <a href="/">
          <img src={logo} width={"100px"} alt="logo"></img>
        </a>
      </div>

      <div className="Signup-Container">
        <div className="Signup-title">
          <h2>Create your Carpedm account</h2>
          <p>Ready to Explore the world? Join us!</p>
        </div>
        <form onSubmit={handleSubmit} className="signup-form">
          <div>
            <input
              type="text"
              name="firstName"
              placeholder="First name as per passport"
              onChange={handleChange}
            />
            <input
              type="text"
              name="lastName"
              placeholder="Last name as per passport"
              onChange={handleChange}
            />
            <input
              type="email"
              name="email"
              placeholder="Enter your email address"
              onChange={handleChange}
            />
            <input
              type="password"
              name="password"
              placeholder="Password"
              onChange={handleChange}
            />
            <input
              type="password"
              name="confirmPassword"
              placeholder="Confirm password"
              onChange={handleChange}
            />
          </div>
          <div>
            <button type="submit" className="signup-btn">
              Create an account
            </button>
          </div>
        </form>
        <div className="separator">
          <hr />
          <p>or</p>
          <hr />
        </div>
        <div className="sign-login">
          <p>
            Already have an account? <a href="/login">Login</a>
          </p>
        </div>
        <div className="agent-login">
          <p>Are you an agent?</p>
        </div>
        <div>
          <button className="agent-btn-login">Continue to Sherpa</button>
        </div>
        <div className="policy-container">
          <span>Privacy policy</span>
          <span>Inactive account?</span>
        </div>
      </div>
    </>
  );
};

export default Signup;
