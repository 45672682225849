import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// Async thunk for fetching all tours
export const fetchAllTours = createAsyncThunk(
  "Tours/fetchAllTours",
  async ({ apikey, page = 1 }, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `https://rest.gadventures.com/tour_dossiers?advertised_departures__notnull=1&max_per_page=24&page=${page}`,
        {
          headers: {
            "X-Application-Key":
              "live_6e34a234be449f608524d6ddeb1c0d1486f22825",
            Accept: "application/json",
            "Accept-Language": "en",
            // Host: 'rest.gadventures.com',
          },
        }
      );
      return response.data.results;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

// Async thunk for handling search based on user input
export const searchTours = createAsyncThunk(
  "Tours/searchTours",
  async ({ inputValue, apikey, page = 1 }, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `https://rest.gadventures.com/tour_dossiers/?geography.region.name=${inputValue}&advertised_departures__notnull=1&max_per_page=24&page=${page}`,
        {
          headers: {
            "X-Application-Key": apikey,
            Accept: "application/json",
            "Accept-Language": "en",
            Host: "rest.gadventures.com",
          },
        }
      );
      return response.data.results;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

// Async thunk for fetching tours based on city/search
export const searchToursCityBased = createAsyncThunk(
  "Tours/searchToursCityBased",
  async ({ inputValue, apikey, page = 1 }, { rejectWithValue }) => {
    try {
      // First API call
      const firstResponse = await axios.get(
        `https://rest.gadventures.com/tour_dossiers?name=${inputValue}&advertised_departures__notnull=1&max_per_page=24&page=${page}`,
        {
          headers: {
            "X-Application-Key": apikey,
            Accept: "application/json",
            "Accept-Language": "en",
            Host: "rest.gadventures.com",
          },
        }
      );

      // Check if results are found, if not, make the second API call
      if (firstResponse.data.results.length === 0) {
        const secondResponse = await axios.get(
          `https://rest.gadventures.com/tour_dossiers/?geography.region.name=${inputValue}&advertised_departures__notnull=1&max_per_page=24&page=${page}`,
          {
            headers: {
              "X-Application-Key": apikey,
              Accept: "application/json",
              "Accept-Language": "en",
              Host: "rest.gadventures.com",
            },
          }
        );
        return secondResponse.data.results;
      }

      // Return the results from the first response if found
      return firstResponse.data.results;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

// Fetching tour details
export const fetchTourDetails = createAsyncThunk(
  "Tours/fetchTourDetails",
  async ({ searchResults, apikey }, { rejectWithValue }) => {
    try {
      const details = {};

      // Use Promise.all to fetch details for all tours concurrently
      await Promise.all(
        searchResults.map(async (tour) => {
          const response = await axios.get(
            `https://rest.gadventures.com/tour_dossiers/${tour.id}`,
            {
              headers: {
                "X-Application-Key": apikey,
                Accept: "application/json",
                "Accept-Language": "en",
              },
            }
          );
          details[tour.id] = response.data;
        })
      );

      return details;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

// Async thunk for handling search based on user input
export const getItinerariess = createAsyncThunk(
  "Tours/getItineraries",
  async ({ itineraries, apiKey }, { rejectWithValue }) => {
    try {
      const response = await axios.get(itineraries, {
        headers: {
          "X-Application-Key": apiKey,
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

// Async thunk for handling search based on user input
export const getItineraries = createAsyncThunk(
  "Tours/getItineraries",
  async ({ itineraries, apiKey }, { rejectWithValue }) => {
    try {
      const response = await axios.get(itineraries, {
        headers: {
          "X-Application-Key": apiKey,
        },
      });
      const itinerary = response.data;

      const enrichedItinerary = await Promise.all(
        itinerary.days.map(async (day) => {
          const enrichedComponents = await Promise.all(
            day.components.map(async (component) => {
              let dossier = null;
              if (component.type === "ACTIVITY") {
                dossier = component.activity_dossier;
              } else if (component.type === "TRANSPORT") {
                dossier = component.transport_dossier;
              } else if (component.type === "ACCOMMODATION") {
                dossier = component.accommodation_dossier;
              }

              if (dossier?.href) {
                const dossierResponse = await axios.get(dossier.href, {
                  headers: {
                    "X-Application-Key": apiKey,
                  },
                });
                return { ...component, dossierData: dossierResponse.data };
              }

              return component;
            })
          );

          return { ...day, components: enrichedComponents };
        })
      );

      return { ...itinerary, days: enrichedItinerary };
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

// Async thunk for handling search based on user input
export const getItinerariesSSSS = createAsyncThunk(
  "Tours/getItineraries",
  async ({ itineraries, apiKey }, { rejectWithValue }) => {
    try {
      // Step 1: Fetch the initial itinerary details
      const response = await axios.get(itineraries, {
        headers: {
          "X-Application-Key": apiKey,
        },
      });
      const itinerary = response.data;

      // Step 2: Enrich the itinerary by fetching dossier details
      const enrichedItinerary = await Promise.all(
        itinerary.days.map(async (day) => {
          const enrichedComponents = await Promise.all(
            day.components.map(async (component) => {
              let activityDossiers = [];

              // Check if component has more than one activity
              if (component.type === "ACTIVITY") {
                if (Array.isArray(component.activities)) {
                  // Handle multiple activities
                  activityDossiers = await Promise.all(
                    component.activities.map(async (activity) => {
                      if (activity.activity_dossier?.href) {
                        const response = await axios.get(
                          activity.activity_dossier.href
                        );
                        return {
                          ...activity,
                          fetchedActivityData: response.data,
                        };
                      }
                      return activity;
                    })
                  );
                } else if (component.activity_dossier?.href) {
                  // Handle single activity
                  const response = await axios.get(
                    component.activity_dossier.href
                  );
                  activityDossiers = [
                    {
                      ...component.activity_dossier,
                      fetchedActivityData: response.data,
                    },
                  ];
                }
              }

              let dossier = null;
              if (component.type === "TRANSPORT") {
                dossier = component.transport_dossier;
              } else if (component.type === "ACCOMMODATION") {
                dossier = component.accommodation_dossier;
              }

              // Fetch the dossier details if the href exists
              if (dossier?.href) {
                const dossierResponse = await axios.get(dossier.href);
                return {
                  ...component,
                  fetchedDossierData: dossierResponse.data,
                  activities: activityDossiers,
                };
              }

              // Return component with activities or other dossier types
              return { ...component, activities: activityDossiers };
            })
          );

          // Return the day with enriched components
          return { ...day, components: enrichedComponents };
        })
      );

      // Return the enriched itinerary
      return { ...itinerary, days: enrichedItinerary };
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

// Async thunk for fetching departure details
export const fetchDepartureDetails = createAsyncThunk(
  "Tours/fetchDepartureDetails",
  async ({ searchResults, apikey }, { rejectWithValue }) => {
    try {
      const departureDetails = {};

      // Use Promise.all to fetch details concurrently
      const departurePromises = searchResults.flatMap((tour) =>
        tour.advertised_departures.map(async (departure) => {
          // ?.filter((departure) => departure) // Filter out departures without href
          try {
            const response = await axios.get(departure.departure.href, {
              headers: {
                "X-Application-Key": apikey,
                Accept: "application/json",
                "Accept-Language": "en",
              },
            });

            // Store fetched details (handle potential errors)
            departureDetails[departure.departure.id] = response.data || {};
          } catch (err) {
            // Handle individual request errors (optional)
            console.error(
              `Failed to fetch details for departure ${departure.departure.id}:`,
              err
            );
          }
        })
      );

      // Wait for all departure fetches to complete
      await Promise.all(departurePromises);
      return departureDetails;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

// Async thunk for fetching details of a single tour
export const fetchSingleTourDetails = createAsyncThunk(
  "Tours/fetchSingleTourDetails",
  async ({ id, apiKey }, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `https://rest.gadventures.com/tour_dossiers/${id}`,
        {
          headers: {
            "X-Application-Key": apiKey,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

// Async thunk for fetching conversion rates
export const fetchConversionRates = createAsyncThunk(
  "Tours/fetchConversionRates",
  async ({ ratesAPIKey }, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `https://v6.exchangerate-api.com/v6/${ratesAPIKey}/latest/USD`
      );
      return response.data.conversion_rates;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

export const toursSlice = createSlice({
  name: "Tours",
  initialState: {
    allTours: [],
    searchResults: [],
    tourDetails: {},
    tourDeparturesDetails: {},
    conversionRates: {},
    itinerary: {},
    singleTourDetails: null,
    isLoading: false,
    error: null,
  },
  reducers: {
    clearSearchResults: (state) => {
      state.searchResults = state.allTours;
    },
    clearSingleTourDetails: (state) => {
      state.singleTourDetails = null; // Reset single tour details
    },

    // Reducer to update USD amounts after conversion rates are fetched
    updateUsdAmount: (state) => {
      state.allTours = state.allTours.map((tour) => {
        tour.advertised_departures = tour.advertised_departures.map(
          (departure) => {
            const currency = departure.currency;
            const conversionRate = state.conversionRates[currency] || 1; // Default to 1 if USD
            departure.usdAmount = departure.amount / conversionRate;
            return departure;
          }
        );
        return tour;
      });
      state.searchResults = state.allTours; // Also update searchResults
    },
  },
  extraReducers: (builder) => {
    // Fetching all tours
    builder
      .addCase(fetchAllTours.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchAllTours.fulfilled, (state, action) => {
        state.isLoading = false;
        state.allTours = action.payload;
        state.searchResults = action.payload;
      })
      .addCase(fetchAllTours.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });

    // Searching tours
    builder
      .addCase(searchTours.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(searchTours.fulfilled, (state, action) => {
        state.isLoading = false;
        state.searchResults = action.payload;
      })
      .addCase(searchTours.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });

    // Searching tours
    builder
      .addCase(searchToursCityBased.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(searchToursCityBased.fulfilled, (state, action) => {
        state.isLoading = false;
        state.searchResults = action.payload;
      })
      .addCase(searchToursCityBased.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });

    // Fetch tour details
    builder
      .addCase(fetchTourDetails.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchTourDetails.fulfilled, (state, action) => {
        state.isLoading = false;
        state.tourDetails = action.payload;
      })
      .addCase(fetchTourDetails.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });

    // Fetch tour itineraries
    builder
      .addCase(getItineraries.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getItineraries.fulfilled, (state, action) => {
        state.isLoading = false;
        state.itinerary = action.payload;
      })
      .addCase(getItineraries.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });

    // Fetch tour departures details
    builder
      .addCase(fetchDepartureDetails.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchDepartureDetails.fulfilled, (state, action) => {
        state.isLoading = false;
        state.tourDeparturesDetails = action.payload; // Store the fetched tour details
      })
      .addCase(fetchDepartureDetails.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });

    // Fetch single tour details
    builder
      .addCase(fetchSingleTourDetails.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchSingleTourDetails.fulfilled, (state, action) => {
        state.isLoading = false;
        state.singleTourDetails = action.payload; // Store fetched tour details
      })
      .addCase(fetchSingleTourDetails.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });

    // Fetching conversion rates
    builder
      .addCase(fetchConversionRates.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchConversionRates.fulfilled, (state, action) => {
        state.isLoading = false;
        state.conversionRates = action.payload;

        // Loop through all tours and update usdAmount for each advertised_departure
        state.allTours = state.allTours.map((tour) => {
          tour.advertised_departures = tour.advertised_departures.map(
            (departure) => {
              const currency = departure.currency;
              const conversionRate = action.payload[currency] || 1; // Default to 1 if USD
              departure.usdAmount = departure.amount / conversionRate; // Calculate usdAmount
              return departure;
            }
          );
          return tour;
        });

        state.searchResults = state.allTours; // Keep searchResults updated as well
      })
      .addCase(fetchConversionRates.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export const { clearSearchResults, clearSingleTourDetails } =
  toursSlice.actions;

export default toursSlice.reducer;
