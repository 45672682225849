// AgentSignUp.jsx
import "../assets/css/AgentSignUp.css";
import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom"; // Import useNavigate
import logo from "../assets/images/logo.png";
import { useForm } from "react-hook-form";

import { CountryDropdown, RegionDropdown } from "react-country-region-selector";

const AgentSignUp = () => {
  const [country, setCountry] = useState(null);
  const [region, setRegion] = useState(null);
  // Initialize the form using useForm hook
  const {
    register,
    handleSubmit,
    watch,
    setError,
    formState: { errors },
  } = useForm();

  const password = watch("agentDetails.password");

  const handleCountryChange = (val) => {
    setCountry(val);
    setRegion(""); // Reset region when country changes
    // Reset region error when country changes
    if (errors.region) {
      setError("region", { type: "manual", message: "" });
    }
  };

  const handleRegionChange = (val) => {
    setRegion(val);
    // Reset region error when region changes
    if (errors.region) {
      setError("region", { type: "manual", message: "" });
    }
  };
  // const handleCountryChange = (val) => {
  //     setCountry(val);
  //     setRegion(''); // Reset region when country changes
  //     setErrorsObj((prevState) => ({ ...prevState, country: null })); // Clear country error
  // };

  // const handleRegionChange = (val) => {
  //     setRegion(val);
  //     setErrorsObj((prevState) => ({ ...prevState, region: null })); // Clear region error
  // };

  // const handleCountryChange = (val) => {
  //     setCountry(val);
  //     setRegion(''); // Reset region when country changes
  // };

  // const handleRegionChange = (val) => {
  //     setRegion(val);
  // };

  console.log("errors::::::::::::", errors);

  const handleClick = () => {
    if (country == null || region == null) {
      setError("agencyAddress.country", {
        type: "manual",
        message: "Country is required",
      });
      setError("agencyAddress.region", {
        type: "manual",
        message: "Region is required",
      });
      return;
    }
  };
  // Handle form submission
  const onSubmit = async (data) => {
    console.log("data:::::::::::", data);
    console.log("Country::::::::::::", country);
    console.log("Region::::::::::::", region);
    if (country == null || region == null) {
      // setError('agencyAddress.country', { type: 'manual', message: 'Country is required' });
      // setError('agencyAddress.region', { type: 'manual', message: 'Region is required' });
      return;
    }

    // Structure the data as per your provided object
    const postData = {
      agencyRegistration: {
        companyName: data.agencyRegistration.companyName,
        agency: data.agencyRegistration.agency,
      },
      registrationNumber: {
        iataNumber: data.registrationNumber.iataNumber,
        abtaNumber: data.registrationNumber.abtaNumber,
        brNumber: data.registrationNumber.brNumber,
        cliaNumber: data.registrationNumber.cliaNumber,
        tidsNumber: data.registrationNumber.tidsNumber,
      },
      agencyAddress: {
        address: data.agencyAddress.address,
        country: country, // Use selected country
        state: region, // Use selected state/region
        city: data.agencyAddress.city,
        postalCode: data.agencyAddress.postalCode,
      },
      agentDetails: {
        name: data.agentDetails.name,
        email: data.agentDetails.email,
        password: data.agentDetails.password,
        confirmPassword: data.agentDetails.confirmPassword,
        publicPhone: data.agentDetails.publicPhone,
        privatePhone: data.agentDetails.privatePhone,
        fax: data.agentDetails.fax,
      },
      agencyManagerDetails: {
        firstName: data.agencyManagerDetails.firstName,
        lastName: data.agencyManagerDetails.lastName,
        managerEmail: data.agencyManagerDetails.managerEmail,
      },
      activeBookingNumber: data.activeBookingNumber,
    };

    console.log("postData::::::::::::", postData);

    try {
      const response = await axios.post(
        "http://localhost:4000/api/agents/agent-signup",
        postData
      );
      toast.success("Signup successful!");
      console.log(response.data);

      navigate("/");
    } catch (error) {
      toast.error(error.response?.data?.message || "Signup failed!");
      console.error(error.response?.data || error.message);
    }
  };

  //   const [formData, setFormData] = useState({
  //     firstName: "",
  //     lastName: "",
  //     email: "",
  //     password: "",
  //     confirmPassword: "",
  //   });

  const navigate = useNavigate(); // Initialize useNavigate

  // const handleChange = (e) => {
  //     setFormData({ ...formData, [e.target.name]: e.target.value });
  // };

  // const handleSubmitt = async (e) => {
  //     e.preventDefault();

  //     // Validate that password and confirmPassword match
  //     // if (formData.password !== formData.confirmPassword) {
  //     //     toast.error("Passwords do not match!");
  //     //     return; // Stop the submission process if passwords don"t match
  //     // }

  //     try {
  //         const response = await axios.post(
  //             "http://localhost:4000/api/agents/agent-signup",
  //             formData
  //         );
  //         toast.success("Signup successful!");
  //         console.log(response.data);

  //         navigate("/");
  //     } catch (error) {
  //         toast.error(error.response?.data?.message || "Signup failed!");
  //         console.error(error.response?.data || error.message);
  //     }
  // };

  return (
    <>
      <div className="logo">
        <Link to="/">
          <img src={logo} width={"100px"} alt="logo"></img>
        </Link>
      </div>

      <div id="agent-signup-page" className="agent-signup-container">
        <div className="agent-signup-title">
          <h2>Register your travel agency!</h2>
          <p>
            Thank you for your interest! To begin, register your agency below.
            Once approved, you'll be able to grant agent access.
          </p>
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className="agent-signup-form">
          <div className="company-agency">
            <p style={{ color: "#1E1E1E", fontSize: "14px", margin: "0px" }}>
              <span style={{ color: "#C01A1A" }}>IMPORTANT:</span> This is for
              Agency registration. If your business is with G Adventures,
              contact your manager for Sherpa access.
            </p>
            <div>
              <input
                type="text"
                className="input-field"
                name="companyName"
                placeholder="Legal company name*"
                id="companyName"
                {...register("agencyRegistration.companyName", {
                  required: "Company Name is required",
                })}
              />
              {errors.agencyRegistration?.companyName && (
                <p style={{ color: "#C81A1A", margin: 0, fontSize: "12px" }}>
                  {errors.agencyRegistration.companyName.message}
                </p>
              )}
            </div>
            <div>
              <input
                type="text"
                className="input-field"
                name="agency"
                placeholder="Agency group/chain (Leave it blank if none)"
                id="agency"
                {...register("agencyRegistration.agency")}
              />
            </div>
          </div>

          <div className="company-agency">
            <p style={{ color: "#1E1E1E", fontSize: "14px", margin: "0px" }}>
              *One registration number is required to submit your Sherpa
              application. Without it, your agency cannot be approved.
            </p>
            <div>
              <input
                type="text"
                className="input-field"
                name="iataNumber"
                placeholder="IATA number"
                id="iataNumber"
                {...register("registrationNumber.iataNumber")}
              />
            </div>
            <div>
              <input
                type="text"
                className="input-field"
                name="abtaNumber"
                placeholder="ABTA number"
                id="abtaNumber"
                {...register("registrationNumber.abtaNumber")}
              />
            </div>
            <div>
              <input
                type="text"
                className="input-field"
                name="brNumber"
                placeholder="Business registration number"
                id="brNumber"
                {...register("registrationNumber.brNumber")}
              />
            </div>
            <div>
              <input
                type="text"
                className="input-field"
                name="cliaNumber"
                placeholder="CLIA number"
                id="cliaNumber"
                {...register("registrationNumber.cliaNumber")}
              />
            </div>
            <div>
              <input
                type="text"
                className="input-field"
                name="tidsNumber"
                placeholder="TIDS number"
                id="tidsNumber"
                {...register("registrationNumber.tidsNumber")}
              />
            </div>
          </div>

          {/* Agency Address */}
          <div className="company-agency">
            <p style={{ color: "#019BBB", fontSize: "18px", margin: "0px" }}>
              Agency Address
            </p>
            <div>
              <input
                type="text"
                className="input-field"
                name="address"
                placeholder="Address*"
                id="address"
                {...register("agencyAddress.address", {
                  required: "Agency address is required",
                })}
              />
              {errors.agencyAddress?.address && (
                <p style={{ color: "#C81A1A", margin: 0, fontSize: "12px" }}>
                  {errors.agencyAddress.address.message}
                </p>
              )}
            </div>

            <div>
              <CountryDropdown
                style={{ width: "100%", margin: "0px" }}
                value={country}
                onChange={handleCountryChange}
              />
              {errors.agencyAddress?.country && (
                <p style={{ color: "#C81A1A", margin: 0, fontSize: "12px" }}>
                  {errors.agencyAddress?.country.message}
                </p>
              )}
            </div>

            <div>
              <RegionDropdown
                style={{ width: "100%", margin: "0px" }}
                country={country}
                value={region}
                onChange={handleRegionChange}
              />
              {errors.agencyAddress?.region && (
                <p style={{ color: "#C81A1A", margin: 0, fontSize: "12px" }}>
                  {errors.agencyAddress?.region.message}
                </p>
              )}
            </div>

            {/* Country Dropdown */}
            {/* <CountryDropdown
                            value={country}
                            onChange={handleCountryChange}
                            className={errors.country && 'error'}
                            {...register('agencyAddress.country', { required: 'Country is required' })}
                        />
                        {errors.country && <p>{errors.country.message}</p>} */}

            {/* State/Province Dropdown */}
            {/* <RegionDropdown
                            country={country}
                            value={region}
                            onChange={handleRegionChange}
                            className={errors.region && 'error'}
                            {...register('agencyAddress.state', { required: 'State/Province is required' })}
                        />
                        {errors.state && <p>{errors.state.message}</p>} */}
            <div>
              <input
                type="text"
                className="input-field"
                name="city"
                placeholder="City*"
                id="city"
                {...register("agencyAddress.city", {
                  required: "Agency city is required",
                })}
              />
              {errors.agencyAddress?.city && (
                <p style={{ color: "#C81A1A", margin: 0, fontSize: "12px" }}>
                  {errors.agencyAddress.city.message}
                </p>
              )}
            </div>
            <div>
              <input
                type="text"
                className="input-field"
                name="postalCode"
                placeholder="Postal/zip code*"
                id="postalCode"
                {...register("agencyAddress.postalCode", {
                  required: "Agency postal code is required",
                })}
              />
              {errors.agencyAddress?.postalCode && (
                <p style={{ color: "#C81A1A", margin: 0, fontSize: "12px" }}>
                  {errors.agencyAddress.postalCode.message}
                </p>
              )}
            </div>
          </div>

          {/* Agency Details */}
          <div className="company-agency">
            <p style={{ color: "#019BBB", fontSize: "18px", margin: "0px" }}>
              Agency Details
            </p>
            <div>
              <input
                type="text"
                className="input-field"
                name="name"
                placeholder="Agency Name*"
                id="name"
                {...register("agentDetails.name", {
                  required: "Agency name is required",
                })}
              />
              {errors.agentDetails?.name && (
                <p style={{ color: "#C81A1A", margin: 0, fontSize: "12px" }}>
                  {errors.agentDetails.name.message}
                </p>
              )}
            </div>
            <div>
              <input
                type="email"
                className="input-field"
                name="email"
                placeholder="Email Address*"
                id="email"
                {...register("agentDetails.email", {
                  required: "Agency email is required",
                })}
              />
              {errors.agentDetails?.email && (
                <p style={{ color: "#C81A1A", margin: 0, fontSize: "12px" }}>
                  {errors.agentDetails.email.message}
                </p>
              )}
            </div>
            <div>
              <input
                type="password"
                className="input-field"
                name="password"
                placeholder="Password*"
                id="password"
                {...register("agentDetails.password", {
                  required: "Password is required",
                  minLength: {
                    value: 6,
                    message: "Password must be at least 6 characters long",
                  },
                })}
                // {...register('agentDetails.password', { required: 'Password is required' })}
              />
              {errors.agentDetails?.password && (
                <p style={{ color: "#C81A1A", margin: 0, fontSize: "12px" }}>
                  {errors.agentDetails.password.message}
                </p>
              )}
            </div>
            <div>
              <input
                type="password"
                className="input-field"
                name="password"
                placeholder="Confirm Password*"
                id="password"
                {...register("agentDetails.confirmPassword", {
                  required: "Please confirm your password",
                  validate: (value) =>
                    value === password || "Passwords do not match",
                })}
                // {...register('agentDetails.confirmPassword', { required: 'Confirm password is required' })}
              />
              {errors.agentDetails?.confirmPassword && (
                <p style={{ color: "#C81A1A", margin: 0, fontSize: "12px" }}>
                  {errors.agentDetails.confirmPassword.message}
                </p>
              )}
            </div>
            <div>
              <input
                type="text"
                className="input-field"
                name="publicPhone"
                placeholder="Public phone number (General phone number)*"
                id="publicPhone"
                {...register("agentDetails.publicPhone", {
                  required: "Agency public phone number is required",
                })}
              />
              {errors.agentDetails?.publicPhone && (
                <p style={{ color: "#C81A1A", margin: 0, fontSize: "12px" }}>
                  {errors.agentDetails.publicPhone.message}
                </p>
              )}
            </div>
            <div>
              <input
                type="text"
                className="input-field"
                name="privatePhone"
                placeholder="Private number (Supplier private phone)*"
                id="privatePhone"
                {...register("agentDetails.privatePhone", {
                  required: "Agency private phone number is required",
                })}
              />
              {errors.agentDetails?.privatePhone && (
                <p style={{ color: "#C81A1A", margin: 0, fontSize: "12px" }}>
                  {errors.agentDetails.privatePhone.message}
                </p>
              )}
            </div>
            <div>
              <input
                type="text"
                className="input-field"
                name="fax"
                placeholder="Fax"
                id="fax"
                {...register("agentDetails.fax")}
              />
              {errors.agentDetails?.fax && (
                <p style={{ color: "#C81A1A", margin: 0, fontSize: "12px" }}>
                  {errors.agentDetails.fax.message}
                </p>
              )}
            </div>
          </div>

          {/* Agency Manager */}
          <div className="company-agency">
            <p style={{ color: "#019BBB", fontSize: "18px", margin: "0px" }}>
              Agency Manager
            </p>
            <p style={{ color: "#1E1E1E", fontSize: "14px", margin: "0px" }}>
              <span style={{ color: "#C01A1A" }}>Hold up!</span> This is NOT
              your contact information, but the manager of your agency.
            </p>
            <div>
              <input
                type="text"
                className="input-field"
                name="firstName"
                placeholder="First Name*"
                id="firstName"
                {...register("agencyManagerDetails.firstName", {
                  required: "First name is required",
                })}
              />
              {errors.agencyManagerDetails?.firstName && (
                <p style={{ color: "#C81A1A", margin: 0, fontSize: "12px" }}>
                  {errors.agencyManagerDetails.firstName.message}
                </p>
              )}
            </div>

            <div>
              <input
                type="text"
                className="input-field"
                name="lastName"
                placeholder="Last Name*"
                id="lastName"
                {...register("agencyManagerDetails.lastName", {
                  required: "Last name is required",
                })}
              />
              {errors.agencyManagerDetails?.lastName && (
                <p style={{ color: "#C81A1A", margin: 0, fontSize: "12px" }}>
                  {errors.agencyManagerDetails.lastName.message}
                </p>
              )}
            </div>
            <div>
              <input
                type="text"
                className="input-field"
                name="managerEmail"
                placeholder="Email Address*"
                id="managerEmail"
                {...register("agencyManagerDetails.managerEmail", {
                  required: "Email address is required",
                })}
              />
              {errors.agencyManagerDetails?.managerEmail && (
                <p style={{ color: "#C81A1A", margin: 0, fontSize: "12px" }}>
                  {errors.agencyManagerDetails.managerEmail.message}
                </p>
              )}
            </div>
          </div>

          {/* Other */}
          <div className="company-agency">
            <p style={{ color: "#019BBB", fontSize: "18px", margin: "0px" }}>
              Other
            </p>
            <div>
              <input
                type="text"
                className="input-field"
                name="activeBookingNumber"
                placeholder="Active booking number"
                id="activeBookingNumber"
                {...register("activeBookingNumber")}
              />
              {errors.activeBookingNumber && (
                <p style={{ color: "#C81A1A", margin: 0, fontSize: "12px" }}>
                  {errors.activeBookingNumber.message}
                </p>
              )}
            </div>
          </div>
          <button
            onClick={handleClick}
            type="submit"
            className="agent-signup-btn"
          >
            Register
          </button>
          <div className="agent-sign-login">
            <p className="already-have-account">
              Already registered?{" "}
              <Link className="button" to="/agent-login">
                Login here
              </Link>
            </p>

            <p className="contact">
              If your company operates its own tours and would like to work with
              G Adventures as a supplier, please{" "}
              <Link className="button" to="contact-us">
                Contact us
              </Link>
            </p>
          </div>
        </form>
      </div>
    </>
  );
};

export default AgentSignUp;
