import "../assets/css/PrivacyPolicy.css";
import React from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";

const PrivacyPolicy = () => {
  return (
    <div id="privacy-policy-page" className="privacy-policy-page">
      <div className="hero-section">
        <Navbar />
        <div className="header-section">
          <div className="header">
            <h1 className="heading">Privacy Policy</h1>
            <p className="para">
              CarpeDM Adventures is committed to protecting your personal
              information. We want you to understand our Privacy Policy and what
              it means to you.
            </p>
          </div>
        </div>
      </div>

      <div className="px-[40px] py-[20px] mx-[30px] my-[0px]">
        <span className="text-[#6D6D6D] text-[12px]">
          Effective: September 13, 2007
        </span>
        <p className="text-[#1E1E1E] text-[16px]">
          CarpeDM Adventures is committed to protecting your personal
          information. We want you to understand our Privacy Policy and what it
          means to you. If you have any questions about our Privacy Policy, you
          can contact our Privacy Officer at carpedm.adventures@gmail.com or
          through the contact information posted below.
        </p>
        <p className="text-[#1E1E1E] text-[24px] font-[600]">
          What is personal information and how do we use or disclose it?
        </p>
        <p className="text-[#1E1E1E] text-[16px]">
          Personal information is information about an identifiable individual
          and includes your name, contact information, travel itinerary and
          other information described below.
        </p>
        <div>
          <h1 className="text-[#019BBB] text-[24px] font-[600]">
            Booking Travel
          </h1>
          <p className="text-[#1E1E1E] text-[16px]">
            CARPEDM Adventures may collect, use or disclose your personal
            information, either directly or through our agency and booking
            partners, for the purposes of offering or booking travel services on
            your behalf, such as your name, age, address, phone number, e-mail
            address, emergency contact information, passport information, travel
            medical information (where required), insurance details, ISIC
            information (where applicable), nationality, residency, nutrition
            preferences, trip evaluations, travel and accommodation preferences,
            comments you provide us, trip destination information, and
            payment-related information. This personal information enables us
            provide you with trip information, book flights, accommodations,
            process payments, and provide similar travel services.
          </p>
          <div className="text-[#1E1E1E] text-[16px]">
            <p>
              If you book a CarpeDM Adventures trip, CarpeDM Adventures may rely
              on local service providers. Please be aware that CarpeDM
              Adventures may be required to provide certain personal information
              to our service providers for the purposes of delivering your
              experience. For example, if you book flights through CarpeDM
              Adventures, we will be required to provide certain personal
              information to the airline for the purposes of securing your
              ticket. When you book travel services with us, you consent to
              collection, use and disclosure of your personal information by
              CarpeDM Adventures—and the corresponding collection and use by the
              relevant travel partners or suppliers—for the purposes of
              administering and delivering the travel services you requested.
            </p>
            <ul>
              <li className="mt-2 mb-2">
                Enabling us to better understand your needs as a customer and
                determine eligibility for our products and services.
              </li>
              <li className="mt-2 mb-2">
                Informing you about our products and services, or those of our
                partners that we believe may be of interest to you (provided
                that we have received valid consent from you, if such consent is
                required).
              </li>
              <li className="mt-2 mb-2">
                Providing ongoing service and support, including resolution of
                any issues with the services or products you request from
                CarpeDM Adventures.
              </li>
              <li className="mt-2 mb-2">
                Ensuring the safety and security of our passengers and products.
              </li>
              <li className="mt-2 mb-2">
                Carrying out internal accounting and administration of our
                business.
              </li>
              <li className="mt-2 mb-2">
                Complying with laws, regulations, and immigration requirements.
              </li>
            </ul>
          </div>

          <div className="text-[#1E1E1E] text-[16px]">
            <h1 className="text-[#019BBB] text-[24px] font-[600]">
              Travel Agents and Agencies
            </h1>
            <p>
              CarpeDM Adventures collects personal information (as described
              above) from agents and agencies as required to book CarpeDM
              Adventures’ trips and be properly credited for such booking. If
              you are an agent travelling with CarpeDM Adventures, CarpeDM
              Adventures may also collect employment-related information to
              confirm your eligibility for travel-industry promotions.
            </p>
          </div>
          <div className="text-[#1E1E1E] text-[16px]">
            <h1 className="text-[#019BBB] text-[24px] font-[600]">
              Contests and Newsletters
            </h1>
            <p>
              We may also collect personal information, including contact
              information such as name, address, and phone number or email
              address if you elect to enter a contest or sweepstakes with us,
              subscribe to our newsletter, or request a brochure. We use this
              personal information for the purposes of administering the
              contests/sweepstakes or delivering the information you request.
            </p>
          </div>
          <div className="text-[#1E1E1E] text-[16px]">
            <h1 className="text-[#019BBB] text-[24px] font-[600]">
              Employment
            </h1>
            <p>
              We may also collect personal information, including contact
              information such as name, address, and phone number or email
              address if you elect to enter a contest or sweepstakes with us,
              subscribe to our newsletter, or request a brochure. We use this
              personal information for the purposes of administering the
              contests/sweepstakes or delivering the information you request.
            </p>
            <ul>
              <li>Identify the individual </li>
              <li>Evaluate their suitability for the applied-for position. </li>
            </ul>
            <p>
              This information may include name, contact information,
              educational and employment background, criminal record
              verification, residency and/or citizenship information, and other
              information necessary for us to screen potential employees and
              staff.
            </p>
          </div>

          <div className="text-[#1E1E1E] text-[16px]">
            <h1 className="text-[#019BBB] text-[24px] font-[600]">
              Service Provider Arrangements
            </h1>
            <p>
              In addition to the third party travel suppliers outlined above,
              CarpeDM Adventures may transfer your personal information to third
              parties who provide services on our behalf. For example, we may
              use service providers to authorize and process payments, host our
              customer service operations, serve advertisements on our website,
              send email or other communications, or run our contests and
              promotions. Your personal information may be maintained and
              processed by our service providers in the US or other
              jurisdictions. For information about the polices or practices of
              our service providers, please contact us at the email address
              above. Our service providers are given the information they need
              to perform their designated functions, and we do not authorize
              them to use or disclose your personal information for their own
              purposes.
            </p>
          </div>
          <div className="text-[#1E1E1E] text-[16px]">
            <h1 className="text-[#019BBB] text-[24px] font-[600]">
              European Residents: Transfers of Data Outside Europe
            </h1>
            <p>
              In addition to the third party travel suppliers outlined above,
              CarpeDM Adventures may transfer your personal information to third
              parties who provide services on our behalf. For example, we may
              use service providers to authorize and process payments, host our
              customer service operations, serve advertisements on our website,
              send email or other communications, or run our contests and
              promotions. Your personal information may be maintained and
              processed by our service providers in the US or other
              jurisdictions. For information about the polices or practices of
              our service providers, please contact us at the email address
              above. Our service providers are given the information they need
              to perform their designated functions, and we do not authorize
              them to use or disclose your personal information for their own
              purposes.
            </p>
            <p>
              We may also need to transfer your personal information to travel
              suppliers and other third parties outside of the EEA, for instance
              where a supplier is based outside of the EEA. Countries outside of
              the EEA may not have the same level of privacy laws as exist in
              the UK, Canada and the EEA. We will take reasonable steps to
              ensure that your privacy rights are protected.
            </p>
          </div>

          <div className="text-[#1E1E1E] text-[16px]">
            <h1 className="text-[#019BBB] text-[24px] font-[600]">
              Sale of Business
            </h1>
            <p>
              We may transfer any information we have about you as an asset in
              connection with a merger or sale (including transfers made as part
              of insolvency or bankruptcy proceedings) involving all or part of
              CarpeDM Adventures or as part of a corporate reorganization or
              other change in corporate control.
            </p>
          </div>

          <div className="text-[#1E1E1E] text-[16px]">
            <h1 className="text-[#019BBB] text-[24px] font-[600]">
              Legal and Compliance
            </h1>
            <p>
              We may transfer any information we have about you as an asset in
              connection with a merger or sale (including transfers made as part
              of insolvency or bankruptcy proceedings) involving all or part of
              CarpeDM Adventures or as part of a corporate reorganization or
              other change in corporate control.
            </p>
          </div>
          <div className="text-[#1E1E1E] text-[16px]">
            <h1 className="text-[#019BBB] text-[24px] font-[600]">
              How is your personal information protected?
            </h1>
            <p>
              We may transfer any information we have about you as an asset in
              connection with a merger or sale (including transfers made as part
              of insolvency or bankruptcy proceedings) involving all or part of
              CarpeDM Adventures or as part of a corporate reorganization or
              other change in corporate control.
            </p>
          </div>
          <div className="text-[#1E1E1E] text-[16px]">
            <h1 className="text-[#019BBB] text-[24px] font-[600]">
              Can I access my information and whom do I contact for more
              information?
            </h1>
            <p>
              We may transfer any information we have about you as an asset in
              connection with a merger or sale (including transfers made as part
              of insolvency or bankruptcy proceedings) involving all or part of
              CarpeDM Adventures or as part of a corporate reorganization or
              other change in corporate control.{" "}
            </p>
            <div className="flex flex-col text-[#1E1E1E] text-[16px]">
              <span>Attn: Privacy Officer</span>
              <span>CarpeDM Adventures</span>
              <span>Antepara E4-60 y Los Rios</span>
              <span>Tel: (593) 98 534 1575</span>
              <span>
                E-Mail:
                <span className="text-[#019BBB] text-[16px] underline">
                  carpedm.adventures@gmail.com
                </span>
              </span>
            </div>
          </div>

          <ul className="text-[#1E1E1E] text-[16px]">
            <li className="mt-2 mb-2">
              To be told how long your personal information will be retained by
              us or the basis on which we make decisions about how long we
              retain your personal information.
            </li>
            <li className="mt-2 mb-2">
              To require that your personal information is deleted by us,
              subject to any necessary legal requirements we may have to retain
              the information.
            </li>
            <li className="mt-2 mb-2">
              To object to our processing of your personal information or to
              restrict processing of your personal information (but we may be
              unable to provide certain services to you without processing this
              information).
            </li>
            <li className="mt-2 mb-2">
              To receive a copy of any personal information we hold about you in
              a portable format.
            </li>
            <li className="mt-2 mb-2">
              To lodge a complaint with a relevant supervising authority (for
              example the Information Commissioner’s Office in the UK).
            </li>
          </ul>

          <div className="text-[#1E1E1E] text-[16px]">
            <h1 className="text-[#019BBB] text-[24px] font-[600]">
              Website Policies and Cookies
            </h1>
            <p>
              Our website uses a technology called a “cookie,” which is a small
              element of data that our website can send to your browser to be
              stored on your computer so that we can recognize your computer
              when you return. If you have registered with our website, cookies
              may provide us and our service providers with information that we
              will use to personalize our website in accordance with your
              preferences. Most web browsers allow you to modify your settings
              to notify you when you receive a cookie or to reject cookies
              entirely. If you decide not to accept cookies from our website,
              you may not be able to take advantage of all of its features.
            </p>
          </div>
          <div className="text-[#1E1E1E] text-[16px]">
            <h1 className="text-[#019BBB] text-[24px] font-[600]">
              Third Party Cookies
            </h1>
            <p>
              Our website uses a technology called a “cookie,” which is a small
              element of data that our website can send to your browser to be
              stored on your computer so that we can recognize your computer
              when you return. If you have registered with our website, cookies
              may provide us and our service providers with information that we
              will use to personalize our website in accordance with your
              preferences. Most web browsers allow you to modify your settings
              to notify you when you receive a cookie or to reject cookies
              entirely. If you decide not to accept cookies from our website,
              you may not be able to take advantage of all of its features.
            </p>
          </div>
          <div className="text-[#1E1E1E] text-[16px]">
            <h1 className="text-[#019BBB] text-[24px] font-[600]">
              Changes to this Privacy Policy
            </h1>
            <p>
              Our website uses a technology called a “cookie,” which is a small
              element of data that our website can send to your browser to be
              stored on your computer so that we can recognize your computer
              when you return. If you have registered with our website, cookies
              may provide us and our service providers with information that we
              will use to personalize our website in accordance with your
              preferences. Most web browsers allow you to modify your settings
              to notify you when you receive a cookie or to reject cookies
              entirely. If you decide not to accept cookies from our website,
              you may not be able to take advantage of all of its features.
            </p>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default PrivacyPolicy;
