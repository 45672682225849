import "../assets/css//GetInTouch.css";
import React from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { submitContactForm } from "../redux/getInTouch";

const GetInTouch = () => {
  const { isFormLoading, formErrorMessage, formSubmissionSuccess } =
    useSelector((store) => store.GetInTouch);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm();

  const onSubmit = (data) => {
    // Dispatch the async thunk to submit form data
    dispatch(submitContactForm(data))
      .unwrap()
      .then((response) => {
        toast.success(response?.message || "Form submitted successfully");
        navigate("/");
      })
      .catch((error) => {
        toast.error(error || "Message sent failed!");
      });
  };
  return (
    <div id="get-in-touch-page" className="get-in-touch-page bg-[#F7F7F7]">
      <div className="hero-section">
        <Navbar />
        <div className="header-section">
          <div className="header">
            <h1 className="heading">Get In Touch</h1>
          </div>
        </div>
      </div>

      {/* Form */}
      <section className="py-1 bg-blueGray-50g">
        <div className="px-[40px] pt-[20px]">
          <p className="text-[#1E1E1E] text-[16px]">
            Fill out this form and our agent will contact you soon
          </p>
        </div>
        <div className="w-full lg:w-8/12 px-4 mx-auto mt-6">
          <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
            <div className="flex-auto px-4 lg:px-10 py-5 pt-5 bg-[#FEFEFE] rounded-[18px]">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="grid grid-cols-12 gap-7">
                  <div className="col-span-12 lg:col-span-6 px-4">
                    <input
                      placeholder="First name"
                      type="text"
                      className="bg-[#F9F8F8] px-3 py-3 placeholder-blueGray-300 text-blueGray-600 rounded text-sm shadow focus:outline-none focus:ring-none w-full ease-linear transition-all duration-150 border-[rgba(53,53,53,0.5)] border-[1px]"
                      name="firstName"
                      id="firstName"
                      {...register("firstName", {
                        required: "First name is required",
                      })}
                    />
                    {errors.firstName && (
                      <p
                        style={{
                          color: "#C81A1A",
                          margin: 0,
                          fontSize: "12px",
                        }}
                      >
                        {errors.firstName.message}
                      </p>
                    )}
                  </div>
                  <div className="col-span-12 lg:col-span-6 px-4">
                    <input
                      placeholder="Last name"
                      type="text"
                      className="bg-[#F9F8F8] px-3 py-3 placeholder-blueGray-300 text-blueGray-600 rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 border-[rgba(53,53,53,0.5)] border-[1px]"
                      name="lastName"
                      id="lastName"
                      {...register("lastName", {
                        required: "Last name is required",
                      })}
                    />
                    {errors.lastName && (
                      <p
                        style={{
                          color: "#C81A1A",
                          margin: 0,
                          fontSize: "12px",
                        }}
                      >
                        {errors.lastName.message}
                      </p>
                    )}
                  </div>
                </div>
                <div className="flex">
                  <div className="w-full px-4">
                    <input
                      placeholder="Email"
                      type="email"
                      className="bg-[#F9F8F8] w-full px-3 py-3 placeholder-blueGray-300 text-blueGray-600 rounded text-sm shadow focus:outline-none focus:ring ease-linear transition-all duration-150 border-[rgba(53,53,53,0.5)] border-[1px]"
                      name="email"
                      id="email"
                      {...register("email", { required: "Email is required" })}
                    />
                    {errors.email && (
                      <p
                        style={{
                          color: "#C81A1A",
                          margin: 0,
                          fontSize: "12px",
                        }}
                      >
                        {errors.email.message}
                      </p>
                    )}
                  </div>
                </div>

                <div className="flex">
                  <div className="w-full px-4">
                    <input
                      placeholder="Subject"
                      type="text"
                      className="bg-[#F9F8F8] px-3 py-3 placeholder-blueGray-300 text-blueGray-600 rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 border-[rgba(53,53,53,0.5)] border-[1px]"
                      name="subject"
                      id="subject"
                      {...register("subject", {
                        required: "Subject is required",
                      })}
                    />
                    {errors.subject && (
                      <p
                        style={{
                          color: "#C81A1A",
                          margin: 0,
                          fontSize: "12px",
                        }}
                      >
                        {errors.subject.message}
                      </p>
                    )}
                  </div>
                </div>

                <div className="flex flex-wrap">
                  <div className="w-full lg:w-12/12 px-4">
                    <textarea
                      placeholder="Your message"
                      type="text"
                      className="bg-[#F9F8F8] px-3 py-3 placeholder-blueGray-300 text-blueGray-600 rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 border-[rgba(53,53,53,0.5)] border-[1px]"
                      rows="4"
                      name="message"
                      id="message"
                      {...register("message", {
                        required: "Message is required",
                      })}
                    ></textarea>
                    {errors.message && (
                      <p
                        style={{
                          color: "#C81A1A",
                          margin: 0,
                          fontSize: "12px",
                        }}
                      >
                        {errors.message.message}
                      </p>
                    )}
                  </div>
                </div>

                <div className="lg:px-4 px-0">
                  <button
                    disabled={isFormLoading || !isValid}
                    type="submit"
                    className={`bg-[#019BBB] text-[16px] text-[#FEFEFE] w-full lg:w-48 h-16 ${
                      isFormLoading || !isValid
                        ? "opacity-[.5] pointer-events-none"
                        : ""
                    }`}
                  >
                    Submit form
                  </button>
                </div>

                {/* Show error and success messages */}
                {formErrorMessage && (
                  <p className="px-4" style={{ color: "#C81A1A" }}>
                    {formErrorMessage}
                  </p>
                )}
                {formSubmissionSuccess && (
                  <p className="px-4" style={{ color: "#4CAF50" }}>
                    {formSubmissionSuccess}
                  </p>
                )}
              </form>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default GetInTouch;
