import React from "react";

const Button = ({ children, onClick = "", type = "submit" }) => {
  const style = {
    backgroundColor: "var(--button-bg-blue)",
    color: "var(--form-bg-color)",
    padding: "20px 25px",
    border: "none",
    borderRadius: "12px",
    cursor: "pointer",
    fontSize: "var(--button-font-size)",
    width: "100%",
    marginTop: "25px",
  };

  return (
    <div>
      <button style={style} onClick={onClick} type={type}>
        {children}
      </button>
    </div>
  );
};

export default Button;
