import "../assets/css/AboutUs.css";
import React from "react";
import { Link } from "react-router-dom";
import Navbar from "./Navbar";
import Footer from "./Footer";
import eyeImg from "../assets/images/eye.png";
import targetImg from "../assets/images/target.png";
import teamMember1 from "../assets/images/team-member-1.png";
import teamMember2 from "../assets/images/team-member-2.png";
import teamMember3 from "../assets/images/team-member-3.png";
import teamMember4 from "../assets/images/team-member-4.png";
import teamMember5 from "../assets/images/team-member-5.png";
import AlternatingLayoutAboutUs from "./aboutUs/AlternatingLayoutAboutUs";

const items = [
  {
    image: teamMember1,
    alt: "Image 1",
    title: "CEO Cookie",
    description:
      "Meet Cookie, the dynamic rescue dog who crawled from under a car and straight into our hearts here at CarpeDM. After spending a magical night with our team, Cookie has become an ambassador for all things positive, bringing joy and enthusiasm wherever she goes.\nHer infectious energy and unwavering spirit have made her a beloved symbol of hope and resilience. And now, in recognition of her extraordinary talents and unwavering dedication, Cookie has been appointed as our CEO. That is right, folks – this little ball of fur is now in charge!\nUnder Cookies inspired leadership, we are confident that CarpeDM will continue to thrive and spread its message of positivity and empowerment far and wide. So come join us on this wild and wonderful journey, and let Cookie show you the true meaning of razzle dazzle.",
  },
  {
    image: teamMember2,
    alt: "Image 2",
    title: "Pablo Vivas",
    description:
      "Picture this: after years of working in the corporate world, sets off to his ancestral roots in Ecuador to reconnect with his family and his heritage. Little did he know that this journey would lead him to found one of the most reputable travel companies in the region - CarpeDM Adventures.\nFast forward 15 years, and CarpeDM Adventures continues to be the top choice for thousands of independent and small group travelers. But what sets them apart is their commitment to responsible tourism and community development. Currently investors in Caiman & Tucan Ecolodge in Cuyabeno. With their passion for adventure and love for Ecuador, CarpeDM Adventures has become a beacon of hope for local communities and travelers alike.\nSo, if you are looking for a travel company that not only offers breathtaking experiences but also makes a positive impact, look no further than CarpeDM Adventures. Because with them, you are not just a tourist - you are part of a movement.",
  },
  {
    image: teamMember3,
    alt: "Image 3",
    title: "Dorian Turner",
    description:
      "Dorian has lived in Ecuador for over 9 years now and for most of that time, he has been working with Carpedm Adventures. He has a passion for travel and new experience that he loves to share with others. His first Love in Ecuador is the Amazon rainforest, he has his own reforestation project reforesting 17 hectares of deforested forest and protecting it for future generations! Exploring the forest and working to improve the lives of Indigenous communities. His second love is the mountains, Hiking in the mountains is his family hobby. With his experience and knowledge of this great country, he will be able to help you plan a tip of a lifetime for you!",
  },
  {
    image: teamMember4,
    alt: "Image 4",
    title: "Michelle Guerra",
    description:
      "Meet the newest addition to the CarpeDM Adventures team, a beacon of positivity and zest for life. With a deep passion for understanding the intricacies of the human mind and spirit, she boasts an impressive background as a clinical psychologist. But her talents do not stop there. When she is not diving deep into the realms of psychology, she is gracefully moving through yoga poses, crafting exquisite ceramics, or getting lost in the pages of her latest read. \nA true people person, her infectious energy and love for nature make her the perfect companion for any adventure. Whether she is whipping up a delicious vegetarian dish, traveling to a new destination, or simply spending quality time with her beloved Charlie, she embodies the spirit of CarpeDM. Join her as she brings a touch of joy to every journey, reminding us all to seize the day.",
  },
  {
    image: teamMember5,
    alt: "Image 5",
    title: "Alejandro Gavilanez",
    description:
      "Alejandro Gavilanez came to us via the Instituto Superior Tecnológico de Turismo y Patrimonio Yavirac as a Intern for CarpeDM. Alejandro also known as Ale G is outgoing, enthusiastic & a great problem solver. He loves exploring the Andes and doing his best to provide a quality experience for those who chose to visit Ecuador. If he’s not guiding Cotopaxi, Quilotoa, Mindo, Otavalo or one of our free walking tours of Quito, you most likely will find him in the CarpeDM Office with a smile, ready to give you the best advice on Ecuador.",
  },
];
const AboutUs = () => {
  return (
    <div id="about-us-page" className="about-us-page">
      <div className="hero-section">
        <Navbar />
        <div className="header-section">
          <div className="header">
            <h1 className="heading">About us CarpeDM</h1>
            <p className="para">
              At CarpeDM Adventures, we believe in seizing the day while doing
              more. Our mission is to provide transformative travel experiences
              that leave a positive impact on local communities and promote
              sustainable tourism.
            </p>
          </div>
        </div>
      </div>

      <div className="seize-the-day">
        <p className="para-1">
          <span>Seize the Day:</span> Carpe diem is a phrase from a Latin poem
          by Horace (Odes 1.11). It is popularly translated as “seize the day.”
          An interpretation of the phrase might be an existential cautionary
          term, much like “eat, drink, travel, and be merry, for tomorrow we
          die,” emphasizing making the most current opportunities because life
          is short. Time is fleeting.
        </p>
        <p className="para-2">
          Carpe diem has been modified to CarpeDM to reflect an evolution in
          meaning that not only encompasses the idea of “seizing the day” but
          also adding the element of “Doing More ” (hence DM) in terms of giving
          back to the local community in which we operate. Travel is a great way
          to provide unique life-changing experiences while leveling the playing
          field for wealth distribution.
        </p>
      </div>

      {/* Vision & Mission */}
      <div className="vision-mission grid grid-cols-12">
        <div className="hidden lg:block lg:col-span-2"></div>
        <div className="vision col-span-12 lg:col-span-4">
          <div className="flex items-center vision-head">
            <img src={eyeImg} alt="vision" />
            <h3 className="ml-3 font-[600] text-[bold] text-[20px] text-[#1E1E1E]">
              Our <span className="text-[#BE2311]">Vision</span>
            </h3>
          </div>
          <p className="text-[#6D6D6D] text-[16px] font-[500] mt-2 vision-para">
            We are dedicated to enriching local communities by offering
            meaningful, authentic travel experiences that foster cultural
            connections. Through sustainable tourism, we ensure that our
            adventures benefit both travelers and the people of Ecuador.
          </p>
        </div>
        <div className="mission col-span-12 lg:col-span-4">
          <div className="flex items-center mission-head">
            <img src={targetImg} alt="mission" />
            <h3 className="ml-3 font-[600] text-[20px] text-[#1E1E1E]">
              Our <span className="text-[#FEC002]">Mission</span>
            </h3>
          </div>
          <p className="text-[#6D6D6D] text-[16px] font-[500] mt-2 mission-para">
            We create transformative travel experiences that connect travelers
            with local communities while protecting the planet. Our focus is on
            fostering harmony, active participation, and prosperity for both our
            guests and the destinations we visit.
          </p>
        </div>
        <div className="hidden lg:block lg:col-span-1"></div>
      </div>
      <p className="px-[40px] py-[20px] mx-[30px] my-[0px] text-[#1E1E1E] text-[16px]">
        Yes, that's correct. Previously, we implemented the 3.3.3 program, where
        3% of work hours were dedicated to volunteering, community projects, and
        environmental initiatives. Due to the pandemic and operating with a
        smaller team and fewer resources, we restructured it into the GOFAR
        Project. Though smaller in scale, GOFAR maintains our core values and
        embodies the 3.3.3 spirit. Stay tuned as we continue to deliver on this
        initiative through the 3 P's of the GOFAR Project: Planet,
        Participation, and Prosperity.
      </p>
      <p className="px-[40px] py-[20px] mx-[30px] my-[0px] text-[#1E1E1E] text-[16px]">
        <span className="text-[#019BBB] text-[24px] font-[600]">Planet:</span>{" "}
        This refers to the need to protect the planet, its natural resources,
        and the environment for future generations. Which represents the Green
        head in the heart. All the elements that make up nature. This includes
        protecting forests, oceans represented by the Blue, Yellow for Sun and
        Energy, as well as reducing pollution and addressing climate change. It
        also involves protecting the health and well-being of people and
        communities, by ensuring access to clean water, air, and healthy food.
      </p>
      <p className="px-[40px] py-[20px] mx-[30px] my-[0px] text-[#1E1E1E] text-[16px]">
        <span className="text-[#019BBB] text-[24px] font-[600]">
          Participation:
        </span>{" "}
        This refers to the need for people to participate in a better experience
        for all, it starts with you. The silhouette of Red. We can all make a
        difference. What are the full nature of your transactions? We will work
        hard this year to bring you "more better for you" and "good for you
        travel experiences" vs "just fun for you experiences". Together we can
        ensure the programs we offer are made in a way that benefits everyone,
        and that the needs of the planet are taken into account.
      </p>
      <p className="px-[40px] py-[20px] mx-[30px] my-[0px] text-[#1E1E1E] text-[16px]">
        <span className="text-[#019BBB] text-[24px] font-[600]">
          Prosperity:
        </span>{" "}
        This refers to the need to create sustainable economic systems that
        support the planet, people, and communities. The Yellow hand in the
        heart. This includes promoting sustainable business practices, such as
        reducing waste and carbon emissions, as well as supporting local
        businesses and economies. By creating economic systems that are in
        balance with the planet and support the well-being of people and
        communities, we can ensure a prosperous future for all.
      </p>

      {/* Our Core Values */}
      <div className="bg-[#EFFAFF] px-[30px] py-[30px] mx-[70px] my-[0px] text-[#1E1E1E] text-[16px] rounded-[18px]">
        <>
          <h1 className="text-[24px] font-[600]">
            CarpeDM Adventures - Our Core Values
          </h1>
          <p className="text-[16px] mt-3 mb-4">
            At CarpeDM Adventures, our values shape every experience we create,
            guiding us toward sustainable and meaningful travel. From protecting
            the environment to fostering community growth, we are committed to
            responsible tourism.
          </p>
          <ul className="mb-5 list-none">
            <li className="">
              <span className="text-[#019BBB] text-[24px] font-[600]">C</span>
              hanging lives through authentic experiences.
            </li>
            <li className="">
              <span className="text-[#019BBB] text-[24px] font-[600]">A</span>
              iming high and being accountable.
            </li>
            <li className="">
              <span className="text-[#019BBB] text-[24px] font-[600]">R</span>
              edirecting challenges into opportunities.
            </li>
            <li className="">
              <span className="text-[#019BBB] text-[24px] font-[600]">P</span>
              rotecting destinations for future generations.
            </li>
            <li className="">
              <span className="text-[#019BBB] text-[24px] font-[600]">E</span>
              xploring and teaching the importance of sustainability.
            </li>
            <li className="">
              <span className="text-[#019BBB] text-[24px] font-[600]">D</span>
              oing our best for communities.
            </li>
          </ul>

          <ul>
            <li className="mb-4">
              Take the{" "}
              <Link className="text-[#019BBB] text-[16px] font-[500] underline">
                Travel Better pledge
              </Link>{" "}
              to join the movement and voice your commitment to responsible
              tourism:
            </li>
            <li>
              I pledge to “Travel Better” by making a conscious effort to:
            </li>
            <li>LIGHTEN the footprint of my travels</li>
            <li>CONSERVE the wild places I explore</li>
            <li>SUPPORT the people and communities I visit</li>
            <li>RESPECT the wildlife I see</li>
            <li>CELEBRATE cultures authentically and respectfully</li>
            <li>ADVOCATE for sustainable tourism</li>
          </ul>
        </>
      </div>

      {/* Meet Out Team */}
      <div className="px-[40px] py-[20px] mx-[30px] my-[0px]">
        <h1 className="text-[#1E1E1E] text-[24px] font-[600]">Meet our team</h1>
        <p className="text-[#1E1E1E] text-[16px] mt-3 mb-3 w-2/3 meet-our-team">
          Founded by Pablo Vivas, our team includes passionate individuals like
          Dorian Turner, committed to reforestation and responsible tourism. We
          work with dedication to ensure every adventure is enriching for both
          travelers and locals.
        </p>

        <AlternatingLayoutAboutUs items={items} />
      </div>

      {/* Explore More */}
      <div className="px-[40px] py-[20px] mx-[30px] my-[0px]">
        <h1 className="text-[#1E1E1E] text-[24px] font-[600] mb-3">
          Explore More of Ecuador with CarpeDM Adventures
        </h1>
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-12 lg:col-span-6">
            <video className="w-full" controls>
              <source src="video.mp4" type="video/mp4" />
              <source src="video.mp4" type="video/ogg" />
              Your browser does not support the video tag.
            </video>
          </div>
          <div className="col-span-12 lg:col-span-6">
            <video className="w-full" controls>
              <source src="video.mp4" type="video/mp4" />
              <source src="video.mp4" type="video/ogg" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default AboutUs;
