import React, { useEffect, useState, useMemo } from "react";
import { useParams } from "react-router-dom";
import "../../assets/css/bookingTour/reviewBooking.css";
import YourBooking from "./YourBooking";
import { RiEdit2Line } from "react-icons/ri";
import Button from "./Button";
import axios from "axios";

const ReviewBooking = () => {
  const [countries, setCountries] = useState([]);
  const [paymentType, setPaymentType] = useState("card");
  const [cardNumber, setCardNumber] = useState("");
  const [expirationDate, setExpirationDate] = useState("");
  const [cvv, setCvv] = useState("");
  const { id: departureId } = useParams();

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await axios.get(
          "https://api.first.org/data/v1/countries"
        );
        const data = response.data.data;
        setCountries(Object.keys(data).map((key) => data[key]));
      } catch (error) {
        console.error("Error fetching countries:", error);
      }
    };
    fetchCountries();
  }, []);

  const [selectedDepartureId] = useState(departureId);
  const [customerData] = useState({
    firstName: "waqas",
    lastName: "atta",
    title: "Mr",
    dateOfBirth: "1998-04-15",
    email: "waqasatta@email.com",
    nationality: 1,
  });
  const [bookingId, setBookingId] = useState(null);
  const [customerId, setCustomerId] = useState(null);
  const [setDepartureServiceId] = useState(null);

  const API_BASE = "https://rest.gadventures.com";
  const API_KEY = "test_12d9e0e39823372cd0274cd2a1a3530ae30e1db5";
  // const API_KEY = "live_6e34a234be449f608524d6ddeb1c0d1486f22825";

  // Step 1: Creating Booking
  const createBooking = async () => {
    try {
      const response = await axios.post(
        `${API_BASE}/bookings/`,
        { currency: "USD", external_id: "FUN1" },
        {
          headers: {
            "X-Application-Key": API_KEY,
            "Content-Type": "application/json",
          },
        }
      );
      setBookingId(response.data.id);
      console.log("Booking created:", response.data);
    } catch (error) {
      console.error(
        "Error creating booking:",
        error.response?.data || error.message
      );
    }
  };

  // Step 2: Creating Customer
  const createCustomer = async () => {
    const { firstName, lastName, title, dateOfBirth, email, nationality } =
      customerData;

    try {
      const response = await axios.post(
        `${API_BASE}/customers/`,
        {
          name: {
            legal_first_name: firstName,
            legal_last_name: lastName,
            title,
          },
          date_of_birth: dateOfBirth,
          account_email: email,
          nationality: { id: nationality },
        },
        {
          headers: {
            "X-Application-Key": API_KEY,
            "Content-Type": "application/json",
          },
        }
      );
      setCustomerId(response.data.id);
      console.log("Customer created:", response.data);
    } catch (error) {
      console.error(
        "Error creating customer:",
        error.response?.data || error.message
      );
    }
  };

  // Step 3: Creating Departure Service
  console.log("data", bookingId, selectedDepartureId, customerId);
  const createDepartureService = async () => {
    // if (!bookingId || !customerId || !selectedDepartureId) {
    //   alert("Ensure booking, customer, and departure ID are set.");
    //   return;
    // }

    try {
      const response = await axios.post(
        `${API_BASE}/departure_services/`,
        {
          booking: { id: bookingId },
          product: { id: 1364888 },
          customers: [{ id: customerId }],
        },
        {
          headers: {
            "X-Application-Key": API_KEY,
            "Content-Type": "application/json",
          },
        }
      );
      setDepartureServiceId(response.data.id);
      console.log("Departure Service created:", response.data);
    } catch (error) {
      console.error(
        "Error creating departure service:",
        error.response?.data || error.message
      );
    }
  };

  const handleBookingFlow = async () => {
    await createBooking();
    await createCustomer();
    await createDepartureService();
    alert("Booking flow completed!");
  };

  // const handleConfirmPayment = async () => {
  //   let bookingData = {
  //     tourId: id,
  //     // departureId: selectedDepartureId,
  //     travelers: [
  //       {
  //         name: "John Doe",
  //         age: 30,
  //         passportNumber: "A12345678",
  //       },
  //       // Add more travelers as needed
  //     ],
  //     currency: "USD",
  //     payment: {
  //       method: paymentType,
  //       cardNumber: cardNumber,
  //       expirationDate: expirationDate,
  //       cvv: cvv,
  //     },
  //   };
  //   try {
  //     const response = await axios.post(
  //       `https://rest.gadventures.com/bookings`,
  //       bookingData,
  //       {
  //         headers: {
  //           "X-Application-Key":
  //             "test_12d9e0e39823372cd0274cd2a1a3530ae30e1db5",
  //           "Content-Type": "application/json",
  //         },
  //       }
  //     );
  //     console.log("Booking successful:", response);
  //     navigate(`/tours/${id}/booking/payment-successful`);
  //   } catch (error) {
  //     console.error(
  //       "Error creating booking:",
  //       error.response?.data || error.message
  //     );
  //   }
  // };

  const countryOptions = useMemo(() => {
    return countries.map((c) => (
      <option key={c.country} value={c.country}>
        {c.country}
      </option>
    ));
  }, [countries]);

  const handleExpirationDate = (e) => {
    const input = e.target.value.replace(/\D/g, "");

    // Adding a slash after the first two digits
    const formattedInput =
      input.length > 2 ? `${input.slice(0, 2)}/${input.slice(2)}` : input;

    setExpirationDate(formattedInput);
  };

  return (
    <div className="review-booking-container">
      <div className="your-trip">
        <h2>Your Trip</h2>
        <div className="trip-details">
          <h3>Paris to Rome: Picnics & Pizza</h3>
          <div>
            Dates:
            <div>
              <a href="#">October 03-07</a>
              <span>
                <RiEdit2Line />
              </span>
            </div>
          </div>
        </div>

        <h2>Traveler Details</h2>
        <div className="traveler-details">
          <div>
            John Doe
            <div>
              <span>Special request: I have allergies from peanuts</span>
              <a href="#">
                <RiEdit2Line />
              </a>
            </div>
          </div>
          <div>
            <span>
              Sarah Doe{" "}
              <a href="#">
                <RiEdit2Line />
              </a>
            </span>
          </div>
        </div>

        <h2>Choose how to pay</h2>
        <div className="payment-options">
          <label>
            <input
              type="checkbox"
              name="payment-method"
              value={paymentType}
              onChange={() => setPaymentType("card")}
              defaultChecked
            />
            Credit/Debit card
          </label>
          <label>
            <input
              type="checkbox"
              name="payment-method"
              value={paymentType}
              onChange={() => setPaymentType("apple-pay")}
            />
            Apple Pay
          </label>
          <label>
            <input
              type="checkbox"
              name="payment-method"
              value={paymentType}
              onChange={() => setPaymentType("paypal")}
            />
            PayPal
          </label>
        </div>

        <div className="payment-form">
          <input
            type="text"
            placeholder="Card number"
            required
            value={cardNumber}
            onChange={(e) => setCardNumber(e.target.value)}
          />
          <div className="row">
            <input
              type="text"
              placeholder="Expiration"
              value={expirationDate}
              onChange={handleExpirationDate}
              required
            />
            <input
              type="text"
              placeholder="CVV"
              value={cvv}
              onChange={(e) => setCvv(e.target.value)}
              required
            />
          </div>
        </div>

        <div className="billing-address">
          <h2>Billing address</h2>
          <input type="text" placeholder="Street address" required />
          <input type="text" placeholder="Apt or suite number" />
          <input type="text" placeholder="City" required />
          <div className="row">
            <input type="text" placeholder="State" required />
            <input type="text" placeholder="Zip code" required />
          </div>
          <select required>
            <option value="-" disabled selected>
              Country
            </option>
            {countryOptions}
          </select>
        </div>

        <div className="terms">
          <input type="checkbox" required />
          <span>
            I agree to all <a href="#">Terms & conditions</a>
          </span>
        </div>

        <Button onClick={handleBookingFlow}>Confirm payment</Button>
      </div>

      <YourBooking />
    </div>
  );
};

export default ReviewBooking;
